
import {
  ArtApproval,
  ArtApprovalComment,
  ArtApprovalItem,
  ArtApprovalItemStatus,
  ArtApprovalStatus,
  ArtApprovalViewFilter,
  Collaborator,
  CollaboratorRole,
  ID
} from '@graphics-flow/types';
import { orderBy as _orderBy, head as _head, flatMap as _flatMap } from 'lodash-es';

import { GlobalHelpers } from './global.helpers';

export class ApprovalHelper {
  static isOpen(approval: ArtApproval): boolean {
    return !GlobalHelpers.isDate(approval.archivedDate);
  }

  static isArchived(approval: ArtApproval): boolean {
    return approval.status === ArtApprovalStatus.ARCHIVED;
  }

  static getComments(approval: ArtApproval): ArtApprovalComment[] {
    return _flatMap(ApprovalHelper.getItems(approval), 'comments');
  }

  static isApproved(approval: ArtApproval): boolean {
    return !!approval?.lastApprovalDate;
  }

  static isItemApproved(approvalItem: ArtApprovalItem): boolean {
    return approvalItem.status === ArtApprovalItemStatus.APPROVED;
  }

  static getItems(approval: ArtApproval): ArtApprovalItem[] {
    return _flatMap(approval.sections, 'items');
  }

  static getApprovalsCollaboratorsIds(approvals: ArtApproval[]): ID[] {
    // https://blog.usejournal.com/performance-of-javascript-array-ops-2690aed47a50
    return [...new Set(_flatMap(approvals, 'assigneeId'))];
  }

  static countApprovedItemsByStatus(approvalsItems: ArtApprovalItem[], status: ArtApprovalItemStatus): number {
    return approvalsItems.filter((item) => item.status === status).length;
  }

  static getItem(approval: ArtApproval, approvalItemId: ID, versionItemId?: ID): ArtApprovalItem {
    const approvalItem: ArtApprovalItem = ApprovalHelper.getItems(approval).find((item: ArtApprovalItem) => {
      return item.artApprovalItemId === approvalItemId;
    });

    if (versionItemId && approvalItem.artApprovalItemId !== versionItemId) {
      return approvalItem.versions.find((version) => version.artApprovalItemId === versionItemId);
    }

    return approvalItem;
  }

  static needToBeSigned(approval: ArtApproval): boolean {
    const items: ArtApprovalItem[] = ApprovalHelper.getItems(approval);
    const approved: number = ApprovalHelper.countApprovedItemsByStatus(items, ArtApprovalItemStatus.APPROVED);
    return items.length === approved + 1;
  }

  /*
  Given an array of approvals return the ones that match the filters
  statusFilter(OPEN/ARCHIVED)
  viewFilter(ALL/APPROVED/NOT APPROVED),
  createdBy(ID)
   */
  static filterApprovals(approvals: ArtApproval[], statusFilter: ArtApprovalStatus, viewFilter: ArtApprovalViewFilter, assigneeId?: ID): ArtApproval[] {
    return approvals.filter((approval: ArtApproval) => {
      const userFilter: boolean = assigneeId ? approval.assigneeId === assigneeId : true;
      // Logic to show/hide based on approval status
      const matchStatus: boolean = statusFilter === ArtApprovalStatus.OPEN ? ApprovalHelper.isOpen(approval) : ApprovalHelper.isArchived(approval);
      let showArtApproval = false;
      // Logic to show/hide based on if approval is Approved or Not Approved
      if (viewFilter === ArtApprovalViewFilter.All) {
        showArtApproval = true;
      } else if (viewFilter === ArtApprovalViewFilter.Approved) {
        showArtApproval = ApprovalHelper.isApproved(approval);
      } else if (viewFilter === ArtApprovalViewFilter.NotApproved) {
        showArtApproval = !ApprovalHelper.isApproved(approval);
      }
      return userFilter && showArtApproval && matchStatus;
    });
  }

  static lastApprovedItem(value: ArtApproval): ArtApprovalItem {
    const items: ArtApprovalItem[] = _orderBy(ApprovalHelper.getItems(value), 'approvalDate', 'desc');
    return _head(items);
  }

  static userCanApproveItem(collaborator: Collaborator): boolean {
    if (!collaborator) {
      return true;
    }
    return collaborator.role === CollaboratorRole.EDIT;
  }

  static sortItems(artApproval: ArtApproval): ArtApproval {
    const compareFn = (a: ArtApprovalItem, b: ArtApprovalItem) => {
      return a.sortOrder > b.sortOrder ? -1 : 1;
    };
    artApproval.sections[0].items.sort(compareFn);
    return artApproval;
  }

  static canRevealFinalApproval(approval: ArtApproval, artApprovalItemId: ID, status: ArtApprovalItemStatus): boolean {
    const approvalItems: ArtApprovalItem[] = ApprovalHelper.getItems(approval);
    const updateArtApprovalItems: ArtApprovalItem[] = approvalItems.map((item: ArtApprovalItem) => item.artApprovalItemId === artApprovalItemId ? {...item, status} : item);
    return ApprovalHelper.isFinalApprovalItem(updateArtApprovalItems);
  }

  static isFinalApprovalItem(artApprovalItem: ArtApprovalItem[]): boolean {
    const isEveryItemHasStatus = artApprovalItem.every(item => item?.status);
    if (!isEveryItemHasStatus) return false;

    return artApprovalItem.some(item => item.status === ArtApprovalItemStatus.APPROVED);
  }

  static getOriginalItemId(activeVersionItem: ArtApprovalItem, approvalItem: ArtApprovalItem, approvalItemId: ID): ID | null {
    return activeVersionItem.artApprovalItemId !== approvalItem.artApprovalItemId ? approvalItemId : null;
  }

  static doAllVersionsHaveStatus(artApprovalItem: ArtApprovalItem, activeVersionItemId: ID): boolean {
    if (!artApprovalItem.versions.length && artApprovalItem.artApprovalItemId === activeVersionItemId) {
      return !!artApprovalItem?.status;
    } else {
      return artApprovalItem.versions.every(item => item?.status);
    }
  }

  static isAnyItemOrVersionApproved(artApprovalItem: ArtApprovalItem, activeVersionItemId: ID): boolean {
    if (!artApprovalItem.versions.length && artApprovalItem.artApprovalItemId === activeVersionItemId) {
      return artApprovalItem.status === ArtApprovalItemStatus.APPROVED;
    } else {
      return artApprovalItem.versions.some(item => item.status === ArtApprovalItemStatus.APPROVED);
    }
  }

  static getApprovalItemVersionIndex(approvalItem: ArtApprovalItem, activeVersionItemId: ID): number {
    const activeVersionItemIndex: number = approvalItem?.versions?.findIndex((version) => version.artApprovalItemId === activeVersionItemId);
    return ((activeVersionItemIndex >= 0) && activeVersionItemIndex) || 0;
  }
}
