<div id="deleteApprovalItemActionDialog">
  <mat-dialog-content>
    <div id="deleteApprovalItemActionContent" class="flex-container flex-dir-column align-center-middle">
      <mat-icon id="deleteApprovalItemActionIcon" class="mat-40 error-color m-y-3" fontIcon="info_outline"></mat-icon>
      <h2 id="deleteApprovalItemActionTitle" class="t-20-500-p m-b-0">{{ translations.approval.delete_action | translate }}</h2>
      <mat-divider id="deleteApprovalItemActionDivider" class="m-y-3 width-100"></mat-divider>
      <p id="deleteApprovalItemActionNote" class="m-b-0 t-16-400-p">{{ translations.approval.delete_action_note | translate }}</p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div id="approvalItemDeleteActionContainer"
      class="flex-container flex-dir-column width-100">
      <button mat-raised-button
        id="deleteDesignVersionBtn"
        color="warn"
        class="large"
        type="button"
        (click)="deleteArtApprovalItemAction(false)">
        {{ translations.approval.delete_design_version | translate }}
      </button>
      <button mat-raised-button
        id="deleteEntireDesignGroupBtn"
        color="warn"
        class="large m-y-1 m-l-0"
        type="button"
        (click)="deleteArtApprovalItemAction(true)">
        {{ translations.approval.delete_entire_line_item_group | translate }}
      </button>
      <button mat-raised-button
        id="cancelBtn"
        color="secondary"
        class="large m-l-0"
        type="button"
        [mat-dialog-close]="false">
        {{ translations.common.cancel | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
