import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {

  transform(string: string, width: number, allowTruncate: boolean = true): string {
    if (string?.length < width || !allowTruncate) {
      return string;
    } else {
      return string?.slice(0, width) + '...';
    }
  }
}
