import { Injectable } from '@angular/core';
import { addEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ArtApprovalHttpService, CollaboratorHttpService } from '@graphics-flow/api';
import {
  Art,
  ArtApproval, ArtApprovalItem,
  ArtApprovalPackage, Collaborator, CollaboratorLink,
  CollaboratorLinkPackage,
  CollaboratorRole,
  ID
} from '@graphics-flow/types';

import { ApprovalService } from '../approval/approval.service';
import { TimelineService } from '../timeline/timeline.service';
import { CollaboratorQuery } from './collaborator.query';
import { CollaboratorStore } from './collaborator.state';
import { ArtService } from '../art/art.service';
import { TeamService } from '../team/team.service';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorService {

  constructor(
    private readonly collaboratorHttpService: CollaboratorHttpService,
    private readonly collaboratorStore: CollaboratorStore,
    private readonly approvalService: ApprovalService,
    private readonly teamService: TeamService,
    private readonly artService: ArtService,
    private readonly approvalHttpService: ArtApprovalHttpService,
    private collaboratorQuery: CollaboratorQuery,
    private readonly timeLineService: TimelineService
  ) { }

  updateCollaborator(collaboratorId: ID, name: string, email: string, role: CollaboratorRole, approvalId?: ID): Observable<Collaborator> {
    return this.collaboratorHttpService.updateCollaborator(collaboratorId, name, email, role, approvalId).pipe(
      tap((collaborator: Collaborator) => {
        this.upsertCollaboratorEntities(collaborator);
      })
    );
  }

  createNewCollaboratorLink(approvalId: ID, collaboratorId: ID): Observable<CollaboratorLink> {
    return this.collaboratorHttpService.createNewCollaboratorLink(approvalId, collaboratorId);
  }

  getArtApprovalFromSharedLink(approvalId: ID, shareLinkId: ID): Observable<ArtApproval> {
    return this.collaboratorHttpService.getArtApprovalFromSharedLink(approvalId, shareLinkId).pipe(
      map((ap: ArtApprovalPackage) => {
        this.teamService.upsertUserEntities(ap.commentUsers);
        this.upsertCollaboratorEntities(ap.commentCollaborators);
        this.approvalService.upsertApprovalEntities(ap.artApproval);
        this.artService.addArtToStore(ap.art || []);
        this.timeLineService.updateTimelineEvent(ap.artApproval.timelineEvents);
        return ap.artApproval;
      })
    );
  }

  commentOnApproval(comment: string, collaboratorId: ID, approvalId: ID, approvalItemId: ID, parentCommentId?: ID, originalItemId: ID = null): Observable<ArtApproval> {
    return this.collaboratorHttpService.addCommentToApproval(comment, collaboratorId, approvalId, approvalItemId, parentCommentId, originalItemId).pipe(
        tap((createdApproval: ArtApproval) => {
          this.approvalService.updateApprovalEntities(createdApproval.artApprovalId, createdApproval);
        })
      );
  }

  deleteCommentFromApproval(approvalId: ID, collaboratorId: ID, commentId: ID, artApprovalItemId: ID, originalApprovalItemId: ID = null): Observable<ArtApproval> {
    return this.collaboratorHttpService.deleteCommentFromApproval(approvalId, collaboratorId, commentId, artApprovalItemId, originalApprovalItemId).pipe(
      tap((reply: ArtApproval) => {
        this.approvalService.updateApprovalEntities(reply.artApprovalId, reply);
      })
    );
  }

  getArtForApproval(approvalId: ID, collaboratorLinkId: ID): Observable<Art[]> {
    return this.collaboratorHttpService.getArtForApproval(approvalId, collaboratorLinkId).pipe(
      tap((arts: Art[]) => {
        this.artService.addArtToStore(arts);
      })
    );
  }

  getCollaboratorLinkPackage(collaboratorLinkId: ID): Observable<CollaboratorLinkPackage> {
    return this.collaboratorHttpService.getCollaboratorLinkPackage(collaboratorLinkId).pipe(
      tap((data: CollaboratorLinkPackage) => {
        if (data.collaborator) {
          this.upsertCollaboratorEntities(data.collaborator);
        }
      })
    );
  }

  updateArtApprovalItem(approvalId: ID, collaboratorId: ID, artApprovalItem: ArtApprovalItem, originalApprovalItemId: ID = null, actionComment?: string) {
    return this.collaboratorHttpService.updateArtApprovalItem(approvalId, artApprovalItem.artApprovalItemId,
      collaboratorId, artApprovalItem.status, originalApprovalItemId,  actionComment).pipe(
        tap((artApproval: ArtApproval) => {
          this.approvalService.updateApprovalEntities(approvalId, artApproval);
          this.timeLineService.updateTimelineEvent(artApproval.timelineEvents);
        })
      );
  }

  getOrCreateCollaboratorWithEmail(email: string, artApprovalId: ID): Observable<Collaborator> {
    const collaborator = this.collaboratorQuery.getCollaboratorByEmail(email);
    if (collaborator) {
      return of(collaborator);
    }
    return this.approvalHttpService.addCollaboratorToApproval(artApprovalId, null, email, null,null, null, null, true).pipe(
      tap((collaboratorRes: Collaborator) => this.collaboratorStore.update(addEntities(collaboratorRes)))
    );
  }

  upsertCollaboratorEntities(collaborators: Collaborator | Collaborator[]): void {
    this.collaboratorStore.update(upsertEntities(collaborators));
  }
}
