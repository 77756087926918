import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ApiResponse,
  Art,
  ArtApproval,
  ArtApprovalItemStatus,
  ArtApprovalPackage,
  Collaborator,
  CollaboratorLink,
  CollaboratorLinkPackage,
  CollaboratorRole,
  ID
} from '@graphics-flow/types';
import { StringHelpers } from 'shared/util';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorHttpService {

  constructor(private readonly httpService: HttpService) {}

  updateCollaborator(collaboratorId: ID, name: string, email: string, role: CollaboratorRole, approvalId?: ID): Observable<Collaborator> {
    const params = new FormData();
    params.append('collaboratorId', StringHelpers.toString(collaboratorId));
    params.append('name', StringHelpers.toString(name));
    params.append('email', StringHelpers.toString(email));
    params.append('role', StringHelpers.toString(role));
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    return this.httpService.post(this.getUrl('UpdateCollaborator'), params).pipe(
      map((response: ApiResponse<Collaborator>) => response.data)
    );
  }

  createNewCollaboratorLink(approvalId: ID, collaboratorId: ID): Observable<CollaboratorLink> {
    const params = new FormData();
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    params.append('collaboratorId', StringHelpers.toString(collaboratorId));
    return this.httpService.post(this.getUrl('createNewCollaboratorLink'), params).pipe(
      map((response: ApiResponse<CollaboratorLink>) => response.data)
    );
  }

  getArtApprovalFromSharedLink(approvalId: ID, sharedLinkId: ID): Observable<ArtApprovalPackage> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    params.append('sharedLinkId', StringHelpers.toString(sharedLinkId));
    return this.httpService.get(this.getUrl('GetArtApprovalFromSharedLink?'+ params.toString())).pipe(
      map((response: ApiResponse<ArtApprovalPackage>) => response.data)
    );
  }

  getArtForApproval(approvalId: ID, collaboratorId: ID): Observable<Art[]> {
    const params: URLSearchParams = new URLSearchParams();
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    params.append('collaboratorId', StringHelpers.toString(collaboratorId));
    return this.httpService.get(this.getUrl('GetArtForApproval?'+ params.toString())).pipe(
      map((response: ApiResponse<Art[]>) => response.data)
    );
  }

  deleteCommentFromApproval(approvalId: ID, collaboratorId: ID, commentId: ID, artApprovalItemId: ID, originalApprovalItemId: ID = null): Observable<ArtApproval> {
    const params = new FormData();
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    params.append('collaboratorId', StringHelpers.toString(collaboratorId));
    params.append('artApprovalItemId', StringHelpers.toString(artApprovalItemId));
    params.append('artApprovalCommentId', StringHelpers.toString(commentId));
    if (originalApprovalItemId) {
      params.append('originalItemId', StringHelpers.toString(originalApprovalItemId));
    }
    return this.httpService.post(this.getUrl('DeleteArtApprovalComment'), params).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  getCollaboratorLinkPackage(collaboratorLinkId: ID): Observable<CollaboratorLinkPackage> {
    const params = new FormData();
    params.append('collaboratorLinkId', StringHelpers.toString(collaboratorLinkId));
    return this.httpService.post(this.getUrl('GetCollaboratorLinkPackage'), params).pipe(
      map((response: ApiResponse<CollaboratorLinkPackage>) => response.data)
    );
  }

  addCommentToApproval(comment: string, collaboratorId: ID, approvalId: ID, approvalItemId: ID, parentCommentId?: ID, originalItemId:ID = null): Observable<ArtApproval> {
    const params = new FormData();
    params.append('collaboratorId', StringHelpers.toString(collaboratorId));
    params.append('comment', comment);
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    params.append('artApprovalItemId', StringHelpers.toString(approvalItemId));
    params.append('parentCommentId', StringHelpers.toString(parentCommentId));
    if (originalItemId) {
      params.append('originalItemId', originalItemId.toString());
    }
    return this.httpService.post(this.getUrl('AddCommentToApproval'), params).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  updateArtApprovalItem(approvalId: ID, approvalItemId: ID, collaboratorId: ID,
    status: ArtApprovalItemStatus, originalApprovalItemId: ID = null, actionComment?: string): Observable<ArtApproval> {
    const params = new FormData();
    params.append('artApprovalId', StringHelpers.toString(approvalId));
    params.append('artApprovalItemId', StringHelpers.toString(approvalItemId));
    params.append('collaboratorId', StringHelpers.toString(collaboratorId));
    params.append('status', status);
    if (actionComment) {
      params.append('comment', actionComment);
    }
    if (originalApprovalItemId) {
      params.append('originalItemId', StringHelpers.toString(originalApprovalItemId));
    }
    return this.httpService.post(this.getUrl('UpdateArtApprovalItem'), params).pipe(
      map((response: ApiResponse<ArtApproval>) => response.data)
    );
  }

  private getUrl(api: string): string {
    return this.httpService.getUrl(`Collaborator/${api}`);
  }
}
