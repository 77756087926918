import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Translations } from '@graphics-flow/shared/assets';
import { ArtApprovalDeleteDialogData, GenericRemovalDialogData } from '@graphics-flow/types';
import { ApprovalActionsService } from '../../services/approval-actions.service';

@Component({
  templateUrl: './approval-item-delete-action-dialog.component.html',
  styleUrl: './approval-item-delete-action-dialog.component.scss'
})
export class ApprovalItemDeleteActionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ApprovalItemDeleteActionDialogComponent>,
    public readonly translations: Translations,
    public approvalActionsService: ApprovalActionsService,
    @Inject(MAT_DIALOG_DATA) public data: ArtApprovalDeleteDialogData
  ) {}

  deleteArtApprovalItemAction(isItemGroup: boolean){
    const dialogData: GenericRemovalDialogData = <GenericRemovalDialogData> {
      cancelText: this.translations.common.cancel,
      header: this.translations.approval[isItemGroup ? 'delete_line_item_group' : 'delete_design_version'],
      body: this.translations.approval[isItemGroup ? 'delete_line_item_group_text' : 'delete_design_version_text'],
      note: this.translations.approval[isItemGroup ? 'delete_line_item_group_hint' : 'delete_design_version_hint'],
      continueText: this.translations.approval[isItemGroup ? 'delete_line_item_group' : 'delete_version'],
      removalData: isItemGroup ? '' : this.data.activeVersionItemName
    };
    this.approvalActionsService.deleteConfirmationDialog(dialogData, this.data.artApprovalId, this.data.artApprovalItemId, this.data.originalItemId, isItemGroup);
  }
}
