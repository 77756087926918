/* tslint:disable:variable-name no-non-null-assertion no-host-metadata-property */
import { Directive, ElementRef, EventEmitter, Inject, Input, Output, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollStrategy,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { FabulousMenuComponent } from './fabulous-menu.component';
import { merge, Subscription } from 'rxjs';
import { FocusMonitor, FocusOrigin, isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';

@Directive({
  selector: '[fabulousTriggerFor]',
  exportAs: 'fabulousTrigger',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'class': 'fabulous-trigger',
    '(mousedown)': '_handleMousedown($event)',
    '(click)': '_handleClick($event)'
  }
})
export class FabulousTriggerDirective {
  _openedBy: 'mouse' | 'touch' | null = null;
  _panelAnimationState: 'void' | 'enter' = 'void';
  @Input() fabulousTriggerRestoreFocus = true;
  @Input() canToggleMenu: boolean = true;
  /** Event emitted when the associated menu is opened. */
  @Output() readonly menuOpened: EventEmitter<void> = new EventEmitter<void>();
  /** Event emitted when the associated menu is closed. */
  @Output() readonly menuClosed: EventEmitter<void> = new EventEmitter<void>();
  private _portal: TemplatePortal;
  private _overlayRef: OverlayRef | null = null;
  private _closingActionsSubscription = Subscription.EMPTY;
  private _menuCloseSubscription = Subscription.EMPTY;
  private _scrollStrategy: () => ScrollStrategy;

  constructor(private _viewContainerRef: ViewContainerRef,
              private _element: ElementRef<HTMLElement>,
              private _focusMonitor: FocusMonitor,
              private _overlay: Overlay,
              @Inject(MAT_MENU_SCROLL_STRATEGY) scrollStrategy: any
  ) {
    this._scrollStrategy = scrollStrategy;
  }

  private _menuOpen = false;

  get menuOpen(): boolean {
    return this._menuOpen;
  }

  private _menu: FabulousMenuComponent;

  @Input('fabulousTriggerFor')
  get menu() {
    return this._menu;
  }

  set menu(menu: FabulousMenuComponent) {
    if (menu === this._menu) {
      return;
    }

    this._menu = menu;
    this._menuCloseSubscription.unsubscribe();

    if (menu) {
      this._menuCloseSubscription = menu.closed.asObservable().subscribe(() => {
        this._destroyMenu();
      });
    }
  }

  toggleMenu(): void {
    return this._menuOpen ? this.closeMenu() : this.openMenu();
  }

  /** Opens the menu. */
  openMenu(): void {
    if (this._menuOpen) {
      return;
    }

    this._checkMenu();

    const overlayRef = this._createOverlay();
    const overlayConfig = overlayRef.getConfig();

    this._setPosition(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy);
    overlayConfig.hasBackdrop = this.menu.hasBackdrop == null ? true : this.menu.hasBackdrop;
    overlayRef.attach(this._getPortal());

    this._closingActionsSubscription = this._menuClosingActions().subscribe(() => this.closeMenu());
    this._initMenu();

    if (this.menu instanceof FabulousMenuComponent) {
      this.menu._startAnimation();
    }
  }

  /** Closes the menu. */
  closeMenu(): void {
    this.menu.closed.emit();
  }

  /**
   * Focuses the menu trigger.
   * @param origin Source of the menu trigger's focus.
   */
  focus(origin: FocusOrigin = 'program', options?: FocusOptions) {
    if (this._focusMonitor) {
      this._focusMonitor.focusVia(this._element, origin, options);
    } else {
      this._element.nativeElement.focus(options);
    }
  }

  /** Handles mouse presses on the trigger. */
  _handleMousedown(event: MouseEvent): void {
    if (!isFakeMousedownFromScreenReader(event)) {
      // Since right or middle button clicks won't trigger the `click` event,
      // we shouldn't consider the menu as opened by mouse in those cases.
      this._openedBy = event.button === 0 ? 'mouse' : null;
    }
  }

  /** Handles click events on the trigger. */
  _handleClick(): void {
    if (this.canToggleMenu) {
      this.toggleMenu();
    }
  }

  /** Closes the menu and does the necessary cleanup. */
  private _destroyMenu() {
    if (!this._overlayRef || !this.menuOpen) {
      return;
    }

    const menu = this.menu;
    this._closingActionsSubscription.unsubscribe();
    this._overlayRef.detach();
    this._restoreFocus();

    if (menu instanceof FabulousMenuComponent) {
      menu._resetAnimation();
      this._setIsMenuOpen(false);
    } else {
      this._setIsMenuOpen(false);
    }
  }

  private _checkMenu() {
    if (!this.menu) {
      throw Error('This here trigger needs a fabulous-menu to be sealed to for time and eternity!');
    }
  }

  private _setIsMenuOpen(isOpen: boolean): void {
    this._menuOpen = isOpen;
    this._menuOpen ? this.menuOpened.emit() : this.menuClosed.emit();
    this.menu.updateIsOpen(this._menuOpen);
  }

  private _getPortal(): TemplatePortal {
    // Note that we can avoid this check by keeping the portal on the menu panel.
    // While it would be cleaner, we'd have to introduce another required method on
    // `MatMenuPanel`, making it harder to consume.
    if (!this._portal || this._portal.templateRef !== this.menu.templateRef) {
      this._portal = new TemplatePortal(this.menu.templateRef, this._viewContainerRef);
    }

    return this._portal;
  }

  /** Restores focus to the element that was focused before the menu was open. */
  private _restoreFocus() {
    // We should reset focus if the user is navigating using a keyboard or
    // if we have a top-level trigger which might cause focus to be lost
    // when clicking on the backdrop.
    if (this.fabulousTriggerRestoreFocus) {
      if (!this._openedBy) {
        // Note that the focus style will show up both for `program` and
        // `keyboard` so we don't have to specify which one it is.
        this.focus();
      } else {
        this.focus(this._openedBy);
      }
    }

    this._openedBy = null;
  }

  /**
   * This method sets the menu state to open and focuses the first item if
   * the menu was opened via the keyboard.
   */
  private _initMenu(): void {
    this._setIsMenuOpen(true);
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const config = this._getOverlayConfig();
      this._overlayRef = this._overlay.create(config);

      // Consume the `keydownEvents` in order to prevent them from going to another overlay.
      // Ideally we'd also have our keyboard event logic in here, however doing so will
      // break anybody that may have implemented the `MatMenuPanel` themselves.
      this._overlayRef.keydownEvents().subscribe();
    }

    return this._overlayRef;
  }

  /**
   * This method builds the configuration object needed to create the overlay, the OverlayState.
   * @returns OverlayConfig
   */
  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._overlay.position()
                            .flexibleConnectedTo(this._element)
                            .withLockedPosition()
                            .withTransformOriginOn('.fabulous-menu-panel'),
      backdropClass: this.menu.backdropClass || 'cdk-overlay-transparent-backdrop',
      panelClass: this.menu.overlayPanelClass,
      scrollStrategy: this._scrollStrategy()
    });
  }

  /**
   * Sets the appropriate positions on a position strategy
   * so the overlay connects with the trigger correctly.
   * @param positionStrategy Strategy whose position to update.
   */
  private _setPosition(positionStrategy: FlexibleConnectedPositionStrategy) {
    const position = this.menu.position;
    const isVertical = position === 'above' || position === 'below';
    const isBelow = position === 'below';

    const [originY, overlayY]: VerticalConnectionPos[] =
      isVertical
        ? position === 'above' ? ['top', 'bottom'] : ['bottom', 'top']
        : ['center', 'center'];

    const [originX, overlayX]: HorizontalConnectionPos[] =
      isVertical && !isBelow
        ? ['center', 'center']
        : isVertical && isBelow
        ? ['start', 'start']
        : position === 'before' ? ['start', 'end'] : ['end', 'start'];

    positionStrategy.withPositions([
      { originX, originY, overlayX, overlayY },
      { overlayX, overlayY, originX, originY }, // Fallback to the opposite
    ]);
  }

  /** Returns a stream that emits whenever an action that should close the menu occurs. */
  private _menuClosingActions() {
    const backdrop = this._overlayRef?.backdropClick();
    const detachments = this._overlayRef?.detachments();

    return merge(backdrop, detachments);
  }
}
