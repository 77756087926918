import { Component } from '@angular/core';
import { ArtApprovalItem, Collaborator, ID } from '@graphics-flow/types';
import { Translations } from '@graphics-flow/shared/assets';
import { ApprovalDetailQuery, ApprovalHelper, ApprovalService, CollaboratorService } from '@graphics-flow/util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'gf-approval-comments',
  templateUrl: './approval-comments.component.html',
  styleUrls: ['./approval-comments.component.scss']
})
export class ApprovalCommentsComponent {

  private approvalId: ID;
  approvalItem: ArtApprovalItem;
  collaborator: Collaborator;
  isGuest = true;
  isArchived$: Observable<boolean> = this.approvalDetailQuery.isArchived$;
  activeVersionItem$: Observable<ArtApprovalItem> = this.approvalDetailQuery.approvalActiveVersionItem$;

  constructor(public translations: Translations,
              private readonly approvalDetailQuery: ApprovalDetailQuery,
              private readonly collaboratorService: CollaboratorService,
              private readonly approvalService: ApprovalService) {
    this.approvalDetailQuery.collaborator$.pipe(untilDestroyed(this)).subscribe((collaborator: Collaborator) => {
      this.collaborator = collaborator;
    });

    this.approvalDetailQuery.approvalItem$.pipe(untilDestroyed(this)).subscribe((approvalItem: ArtApprovalItem) => {
      this.approvalItem = approvalItem;
    });

    this.approvalDetailQuery.approvalId$.pipe(untilDestroyed(this)).subscribe((approvalId: ID) => {
      this.approvalId = approvalId;
    });

    this.approvalDetailQuery.isGuest$.pipe(untilDestroyed(this)).subscribe((isGuest: boolean) => {
      this.isGuest = isGuest;
    });
  }

  addComment(comment: string, activeVersionItem: ArtApprovalItem, parentId?: ID) {
    const originalApprovalItemId = ApprovalHelper.getOriginalItemId(activeVersionItem, this.approvalItem, this.approvalItem.artApprovalItemId);
    this.collaborator
      ? this.collaboratorService.commentOnApproval(comment, this.collaborator.collaboratorId, this.approvalId, activeVersionItem.artApprovalItemId, parentId, originalApprovalItemId).subscribe()
      : this.approvalService.commentOnApproval(comment, this.approvalId, activeVersionItem.artApprovalItemId, parentId, originalApprovalItemId).subscribe();
  }

  deleteComment(commentId: ID, activeVersionItem: ArtApprovalItem){
    const originalApprovalItemId = ApprovalHelper.getOriginalItemId(activeVersionItem, this.approvalItem, this.approvalItem.artApprovalItemId);
    this.collaborator
      ? this.collaboratorService.deleteCommentFromApproval(this.approvalId, this.collaborator.collaboratorId, commentId, activeVersionItem.artApprovalItemId, originalApprovalItemId).subscribe()
      : this.approvalService.deleteCommentFromApproval(this.approvalId, commentId, activeVersionItem.artApprovalItemId, originalApprovalItemId).subscribe();
  }
}
