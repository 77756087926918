<div class="versionApprovalConfirmationDailog">
  <div mat-dialog-title class="flex-container align-justify align-middle">
    <h2 id="versionApprovalConfirmationHeader" class="m-b-0">{{ translations.approval.version_approval | translate }}</h2>
    <mat-icon mat-dialog-close class="cursor-pointer" fontIcon="close"></mat-icon>
  </div>

  <mat-dialog-content>
    <p id="versionApprovalConfirmationBody" class="m-b-2">{{ translations.approval.version_approval_hint | translate }}</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button id="cancelbtn" color="secondary" class="large" type="button" [mat-dialog-close]="false">
      {{ translations.common.cancel | translate }}
    </button>
    <button mat-raised-button id="continuebtn" color="primary" class="large" type="button" [mat-dialog-close]="true">
      {{ translations.approval.proceed_with_approval | translate }}
    </button>
  </mat-dialog-actions>
</div>
