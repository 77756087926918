
export default {
  approval: {
    add_a_text: 'Add a title here',
    add_collaborator: 'Add Collaborator',
    all_activity_and_comments_will_lost: 'All activity and comment history will be lost',
    all_share_links_inaccessible: 'All share links will be inaccessible',
    anyone_with_link_can_comment: 'When clicking on the shareable link, the user will be prompted to enter their email address. They can then view the art approval and leave comments.',
    approve_artwork: 'Approve Artwork',
    approved_by: 'Approved by',
    art_approvals: 'Art Approvals',
    art_approval_updated: 'Art Approval Updated',
    can_approve: 'Can Approve',
    can_comment: 'Can Comment',
    can_view: 'Can View Only',
    copy_link: 'Copy Link',
    create_an_approval: 'Create an Approval',
    delete_art_approval: 'Delete Art Approval',
    delete_art_approval_description: 'Are you sure you want to delete this Art Approval? Check all of the boxes below in order to delete the following Art Approval:',
    delete_x_art_approval: 'Delete {{label}} Art Approval?',
    drag_n_drop: 'Drag & Drop',
    shareable_link: 'Shareable Link',
    invite_collaborators: "Invite Collaborators",
    invite_pending: 'Invite Pending',
    invite_people: 'Invite people to collaborate',
    leave_feedback: 'Leave feedback, annotate the file, or just say you like something. Comments you and other collaborators post will show up here.',
    link_copied: 'Link copied!',
    message: 'Message',
    move_down: 'Move Down',
    move_to_bottom: 'Move To Bottom',
    move_to_top: 'Move To Top',
    move_up: 'Move Up',
    name_is_required: 'Name is required',
    no_collaborators_invited_yet: 'No collaborators invited yet',
    no_collaborators_invited_yet_note: 'You’ll see the list once you add a collaborator',
    no_comments_yet: 'No comments yet',
    resend_art_approval: "Resend Art Approval",
    resent_to_collaborators: "Art Approval Resent to Collaborators",
    review_term_n_condition: 'Review our Terms and Conditions',
    save_copy_to_my_art: 'Save Copy to My Art',
    search_all_art_approvals: 'Search All Art Approvals',
    search_triggered_text: 'Begin typing to search',
    search_for_art_approvals: 'Search for art approvals',
    send_updates_notification: 'Send Updates Notification',
    type_your_name_to_sign: 'Type Your Name to Sign',
    untitled_art_approval: 'Untitled Art Approval',
    uploaded_files_deleted: 'Uploaded files not stored in My Art will be deleted',
    view_art_approval: 'View Art Approval',
    will_be_notified: 'will be notified',
    write_a_description: 'Write a description',
    you_dont_have_any: 'You don\'t currently have any {{status}} approvals.',
    your_art_file_anywhere: 'your art files anywhere, or click to browse',
    dont_allow_duplicate_collaborators: "Cannot add duplicate email address to Art Approval",
    approve_item: "Approve Item",
    reject_item: "Reject Item?",
    action_optional_msg: "Add an optional note or reason below to provide more information.",
    write_a_comment: "write a comment",
    add_comment: 'Add Comment',
    view_comment: 'View 1 Comment',
    view_n_comments: 'View {{x}} Comments',
    approve_optional_msg: 'Add an optional note or special instructions below to provide more information.',
    anyone_with_the_link: 'Anyone with the link',
    can_comment_and_approve: 'Can Comment & Approve',
    remove_collaborator: 'Remove Collaborator',
    anyone_with_link_can_approve: 'When clicking on the shareable link, the user will be prompted to enter their email address. They can then view the art approval, leave comments, and approve or reject items.',
    anyone_with_link_can_view: 'When using the shareable link, the user will not be prompted to enter an email address and can only view the Art Approval. They cannot comment or approve.',
    access_to_art_approval: 'Access to Art Approval',
    access_to_art_approval_msg: 'Enter your email address to continue, and you will be added to this art approval as a collaborator.',
    view_art_Approval: 'View Art Approval',
    art_approval_archived_successfully: 'Art Approval Archived Successfully',
    art_approval_opened_successfully: 'Art Approval Opened Successfully',
    art_approval_deleted_successfully: 'Art Approval Deleted Successfully',
    adding_to_art_approval: 'Adding to Art Approval',
    related_design_request: 'Related Design Requests',
    attachments: 'Attachments',
    search_focus: 'search focus',
    search_no_result: 'search no result',
    no_of_collaborators_viewed: 'Number of collaborators have viewed art approval',
    total_no_of_comments: 'Total number of comments',
    no_of_items_approved: 'Number of line items approved',
    send_updates_email_to_collaborators: 'Updates Email Sent to Collaborators',
    version_control_x: 'Version Control ({{versionCount}})',
    delete_action: 'Delete Action',
    delete_action_note: 'Do you want to delete this design version or the entire line item group?',
    delete_entire_line_item_group: 'Delete Entire Line Item Group',
    delete_line_item: "Delete Line Item",
    delete_line_item_text: 'Are you sure you want to delete this line item from the Art Approval?',
    delete_line_item_hint: 'Note: This will also delete any comment history for this design. This action cannot be undone.',
    delete_design_version: 'Delete Design Version',
    delete_version: 'Delete Version',
    delete_design_version_text: 'Are you sure you want to delete the following version of your design from the Art Approval?',
    delete_design_version_hint: 'Note: This will also delete any comment history for this design version. This action cannot be undone.',
    delete_line_item_group: 'Delete Line Item Group',
    delete_line_item_group_text: 'Are you sure you want to delete the entire line item group from the Art Approval?',
    delete_line_item_group_hint: 'Note: This will delete all design versions in the group, as well as all the comments for each version. This action cannot be undone.',
    design_already_approved: 'Design Already Approved',
    design_already_approved_hint: 'This design has already been Approved. Change the approval status to add a new version, or add a new line item to the art approval.',
    proceed_with_approval: 'Proceed with Approval',
    version_approval: 'Version Approval',
    version_approval_hint: 'Only one design version can be marked as ‘Approved’. Marking this version as ‘Approved’ will mark all other versions as ‘Rejected’. Do you want to proceed with the approval process?',
    approval_confirmation_item_note_x: 'The following {{itemType}} will be marked as ‘{{actionType}}’:',
    line_item: 'line item',
    design_version: 'design version'
  },
  common: {
    actions: 'Actions',
    add: '+ Add',
    add_a_description: 'Add a description',
    add_new_version: 'Add New Version',
    add_notes_here: 'Add notes here...',
    all: 'All',
    all_changes_saved: 'All changes saved!',
    all_x: 'All ({{count}})',
    of: 'of',
    all_right_reserved: '© {{year}} {{name}}. All rights reserved.',
    all_teams_members: 'All Team Members',
    apply: 'Apply',
    approve: 'Approve',
    approved: 'Approved',
    approved_x: 'Approved ({{count}})',
    archive: 'Archive',
    archived: 'Archived',
    are_you_sure: 'Are you sure?',
    art_approval: 'Art Approval',
    art_type: 'Art Type',
    assignee: 'Assignee',
    back: 'Back',
    background: 'Background',
    background_color: 'Background Color',
    baseUserPrice: '$0',
    beta: 'BETA',
    browse: 'Browse',
    cancel: 'Cancel',
    cancel_delete_all: 'Cancel Delete All',
    clear_selection: 'Clear Selection',
    clone: 'Clone',
    close: 'Close',
    cm: 'Centimeters (cm)',
    collaborator: 'Collaborator',
    colors: {
      hex: 'Hex',
      rgb: 'RGB',
      rgb_with_space: 'R G B',
      cmyk: 'CMYK',
      c: 'C',
      m: 'M',
      y: 'Y',
      k: 'K',
      r: 'R',
      g: 'G',
      b: 'B'
    },
    comments: 'Comments',
    contact_shop: 'Contact Shop',
    continue: 'Continue',
    copy_saved_successfully: 'Copy saved successfully',
    create: 'Create',
    create_and_add_files: 'Create & Add Files',
    create_new: 'Create New',
    created: 'Created',
    creator: 'Creator',
    create_new_art_approval: 'Create New Art Approval',
    customized_stock_art: 'Customized Stock Art',
    customer: 'Customer',
    customize: 'Customize',
    discard: 'Discard',
    discard_changes: 'Discard Changes',
    default: 'Default',
    delete: 'Delete',
    delete_x: 'Delete {{item}} ?',
    delete_all: 'Delete All',
    delete_file: 'Delete File',
    description: 'Description',
    de_select: 'Deselect',
    details: 'Details',
    dimensions: 'Dimensions',
    done: 'Done',
    dont_show_message_again: 'Don’t show this message again',
    download: 'Download',
    downloaded: 'Downloaded',
    downloading: 'Downloading...',
    download_as: 'Download as...',
    drag_and_drop_msg: 'Drag and drop files or folders to upload',
    drag_n_drop: 'Drag & Drop',
    edit: 'Edit',
    edit_mode: 'Edit Mode',
    enable: 'Enable',
    enter_email_address: 'Enter email addresses',
    error: 'Error',
    favorites: 'Favorites',
    flip: 'Flip',
    file_added_successfully: 'File Added Successfully',
    files_moved_successfully: '{{x}} moved successfully',
    file: 'File',
    files: 'Files',
    filter_results: 'Filter Results',
    filter_x_results: '{{count}} Results',
    flip_horizontally: 'Flip horizontally',
    flip_vertically: 'Flip vertically',
    folder: 'Folder',
    folders: 'Folders',
    fonts: 'Fonts',
    get_help: 'Get Help',
    give_feedback: 'Give feedback',
    graphicsFlow: 'Graphics Flow',
    graphics_flow_logo: 'Graphics Flow Logo',
    graphicsFlow_shirts: 'GraphicsFlow Shirts',
    height: 'Height',
    height_with_unit: 'Height ({{x}})',
    help: 'Help',
    help_and_resources: 'Help and Resources',
    i_agree_to_terms: 'I agree to the terms and conditions',
    in: 'Inches (in)',
    internal_notes: 'Internal Notes',
    internal_notes_desc: 'Internal notes are not visible to the customer',
    internal_notes_hint: 'These notes will not be visible to the customer',
    last_modified: 'Last Modified',
    loading_results: 'Loading Results...',
    logo: 'Logo',
    manually_hidden_in_art_portal: 'Manually Hidden in Art Portal',
    menu: 'Menu',
    message: 'Message',
    message_sent_successfully: 'Message sent successfully!',
    mm: 'Millimeters (mm)',
    modified: 'Modified',
    more: 'More',
    more_x: '+ {{x}} More',
    move: 'Move',
    month: 'month',
    name: 'Name',
    need: 'Need',
    need_some_help: 'Need some help?',
    need_some_help_description: 'Watch this video overview to learn how to submit a design request.',
    no_description_added: 'No description added',
    no_keep_changes: 'No, Keep Changes',
    note: 'NOTE:',
    new: 'New',
    no_approvals_desc: 'You don\'t currently have any open approvals.',
    no_files_uploaded: 'No files uploaded',
    no_results_found: 'No results found',
    no_internet_connection: 'No internet connection',
    none: 'None',
    not_approved: 'Not Approved',
    not_approved_x: 'Not Approved ({{count}})',
    not_downloaded: 'Not Downloaded',
    nudge: 'Nudge',
    open: 'Open',
    open_approvals: 'Open Approvals',
    optional: '(optional)',
    powered_by: 'Powered by',
    product_updates: 'Product updates',
    profile: 'Profile',
    private_branding: "Private Branding",
    please: "Please",
    qty: "Qty",
    recently_modified: 'Recently modified',
    reject: 'Reject',
    rejected: 'Rejected',
    remove: 'Remove',
    rename: 'Rename',
    re_open: 'Re-open',
    reply: 'Reply',
    required: 'Required',
    request_details: 'Request Details',
    replace_font: 'Replace Font',
    save: 'Save',
    save_changes: 'Save Changes',
    save_copy: 'Save Copy',
    search: 'Search',
    search_designs: 'Search Designs',
    select: 'Select',
    select_plan: 'Select Plan',
    select_your_plan: 'Select Your Plan',
    selections: 'Selections',
    send: 'Send',
    send_email: 'Send Email',
    send_invites: 'Send Invites',
    send_message: 'Send Message',
    session_expired: 'Session Expired',
    share: 'Share',
    signature: 'Signature',
    size: 'Size',
    sortby: 'Sort By',
    state: 'State',
    status: 'Status',
    stock_art_already_downloaded_message: 'This file has already been downloaded. Downloading it again will not count towards your limit.',
    submitted: 'Submitted',
    tag: 'Tag',
    tags: 'Tags',
    training_videos: 'Training videos',
    try_again: 'Try again',
    try_diffrent_keywords: 'Try different keywords',
    type: 'Type',
    unit: 'Unit',
    unassigned: 'Unassigned',
    unsaved_changes: 'Unsaved Changes',
    unsaved_changes_desc: 'You currently have unsaved changes for your plan. Do you want to leave the page without confirming your plan changes? All changes will be lost, and this cannot be undone.',
    update: 'Update',
    upload: 'Upload',
    upload_in_progress: 'Upload in progress',
    uploaded_files: 'Uploaded Files',
    view_details: 'View Details',
    viewers_do_not_have: 'Viewers do not have permission to use these actions',
    warning_cannot_undone: 'Warning: this action cannot be undone.',
    watermark: 'Watermark',
    width: 'Width',
    width_with_unit: 'Width ({{x}})',
    write_a_comment: 'Write a comment…',
    x_people: '{{x}} people',
    x_results: '{{x}} results',
    x_selected: '{{x}} Selected',
    error_header: 'Uh Oh...',
    error_message: 'The page you requested could not be found or you may no longer have permission to view.',
    error_code: 'Error code: 404',
    service_not_available: 'Our site is currently unavailable due to an upstream outage. We are working with our upstream providers to restore service and will be back online soon.',
    designed_by: 'Designed by ',
    freepik: 'Freepik',
    mo: '/mo',
    new_version:'New Version',
    newest:'Newest',
    original:'Original',
    copy_right_graphicsflow: '© {{x}} GraphicsFlow',
    bulk_upload_in_progress_message: 'Bulk upload in progress. Navigating away will cancel any pending or in-progress items.',
    changes_that_you_made_not_saved: 'Changes that you made may not be saved.',
    yes_cancel_changes: 'Yes, Cancel Changes',
    yesterday: 'Yesterday',
    you_do_not_have_permission: 'You do not have permission',
    you_do_not_have_permission_to_make_plan_changes: 'You do not have access to make plan changes.',
    try_modifying_your_filters: 'Try modifying your filters',
    try_modifying_your_search: 'Try modifying your search',
    view_x_comment: 'View {{x}} Comment',
    view_x_comments: 'View {{x}} Comments',
    infinite_scroll_max_limit_warning_msg: 'To see more results, use the search or filter functions',
    warning: 'Warning',
    please_rotate_device: 'Please Rotate Device',
    landscape_mode_is_not_supported: 'Landscape mode is not supported.',
    please_rotate_your_device: 'Please rotate your device.',
    view_all: 'View All',
    viewing_of_results: 'Viewing {{activeResult}} of {{totalResult}} results',
    version_history:"Version History ({{value}})",
    select_current_results: 'Select Current Results',
    select_all: 'Select All',
    selected_count: '{{currentResult}} of {{totalResult}} selected',
    select_all_x: 'Select All ({{x}})',
    file_have_been_moved_to_the_deleted_files_section: 'File have been moved to the deleted files section',
    folder_have_been_moved_to_the_deleted_files_section: 'Folder have been moved to the deleted files section',
    x_files_have_been_moved_to_the_deleted_files_section: '{{x}} have been moved to the deleted files section',
    deleted_successfully: 'Deleted Successfully',
    art_deleted_successfully: 'File Deleted Successfully',
    folder_deleted_successfully: 'Folder Deleted Successfully',
    or: 'or',
    help_n_resources: 'Help & Resources',
    training_videos_desc: 'Access training videos for every category and topic on the platform. Learn how to master GraphicsFlow and get the most out of your account.',
    product_updates_desc: 'Visit our Product Updates page to review the most recent changes and features on the platform.',
    get_help_desc: 'Do you still have questions or need assistance with your account? We’re here to help. Submit a help request and a member of our team will reach out to you shortly.',
    all_training_videos: 'All Training Videos',
    submit_a_request: 'Submit a Request',
    graphics_flow_dark_logo: 'graphics flow dark logo',
    graphics_flow_white_logo: 'graphics flow white logo',
    inktavo_logo: 'inktavo logo',
    page_not_found: 'page not found',
    windows_logo: 'window logo',
    coral_draw_logo: 'coral draw logo',
    my_art_delete: 'Are you sure you want to delete the following {{x}}?',
    my_art_delete_note: 'Note: Once the {{x}} is deleted, it will be moved to ‘Deleted Files’. Deleted files can be recovered or permanently deleted. If the file has been added to the Art Portal, it will be removed. Design Requests will not be impacted.',
    bulk_delete_header: 'Delete Selected Files',
    bulk_art_delete: 'Are you sure you want to delete the selected files?',
    delete_files: 'Delete Files',
    my_art_delete_note_for_starter_plan: 'Note: Once the {{x}} is deleted, it will be moved to ‘Deleted Files’. Deleted files can be recovered or permanently deleted.'
  },
  design_requests: {
    add_to_existing_art_approval: 'Add to Existing Art Approval',
    search_design_requests: 'Search Design Requests',
    art_approval_Linked: 'Art Approval Linked',
    internal_notes_added: 'Internal Notes Added',
    design_request_updated: 'Design Request Updated',
    design_requests: 'Design Requests',
    create_art_approval: 'Create Art Approval',
    art_approval_name: 'Art Approval Name',
    design_request_follow_up: 'Design Request Follow Up | #{{id}}',
    delete_design_request: 'Delete Design Request',
    delete_design_request_message_line1: 'You’re about to permanently delete the following Design Request:',
    delete_design_request_message_line2: 'Note: Deleting a Design Request will not impact any of the linked Art Approvals. You can also Archive this Design Request instead.',
    design_request_deleted: 'Design Request Deleted',
    selections_added_successfully: 'Selections Added Successfully',
    associated_art_approvals: 'Associated Art Approvals',
    no_associated_art_approvals: 'Not associated with any Art Approvals',
    no_archived_request: 'No Archived Requests',
    no_archived_request_hint: 'Archived Design Requests will be shown here until you delete them.',
    no_open_request: 'No Open Requests',
    no_open_request_hint: 'All caught up! Nothing in Open.',
    create_art_approval_archive: 'Archive Design Request upon creating Art Approval',
    edit_design_notes: 'Edit Design Notes',
    view_in_stock_art: 'View in Stock Art',
    select_a_design_request: 'Select a Design Request',
    submitted_on_x: 'Submitted on {{date}}.',
    welcome_modal: {
      art_portal_benefits: 'Art Portal Benefits:',
      art_portal_benefits_line1: 'Customers can view your entire Stock Art Library from any device',
      art_portal_benefits_line2: 'Remove the guess work and save time in the art process',
      art_portal_benefits_line3: 'Provide an engaging experience and win more business',
      description: 'Setup your Art Portal and share the link with your customers to start receiving Design Requests.',
      what_is_art_portal: 'What is your GraphicsFlow Art Portal?',
      setup_your_art_portal: 'Setup your Art Portal',
      requires_admin_permissions: 'Requires Admin Permissions'
    },
    art_approval_created: 'Art Approval Created',
    open_empty_state: 'open empty state',
    empty_state_archived: 'empty state archived',
    file_not_available: {
      description: 'This file has been deleted and is not available to view in My Art. If the file has not yet been permanently deleted, it may still be recovered from the Deleted Files repository. The file can also be downloaded from the Design Request itself.',
      ok_got_it: 'OK, Got it',
      title: 'File Not Available'
    }
  },
  profile: {
    change_password: 'Change Password',
    confirm_new_password: 'Confirm New Password',
    confirm_password: 'Confirm Password',
    confirm_password_not_match: 'Password not matched with confirm password',
    confirm_password_required: 'Confirm password required',
    create_account: 'Create Account',
    created_successfully: 'Your account was created successfully. Please sign in to continue',
    current_password: 'Current Password',
    current_password_not_valid: 'Current password is not valid',
    current_password_required: 'Current password required',
    didnt_get_email: 'Didn\'t get an email?',
    dont_have_account_yet: 'Don\'t have an account yet?',
    edit_organization: 'Edit Organization',
    edit_profile: 'Edit Profile',
    email: 'Email',
    email_address: 'Email Address',
    email_address_required: 'Email Address required',
    email_already_exists: 'An account with that email address already exists',
    email_not_valid: 'Email is not valid',
    email_required: 'Email required',
    first_name: 'First Name',
    first_name_required: 'First name required',
    forgot_password: 'Forgot password?',
    forgot_password_instruction: 'Enter your email address and we\'ll send you a link to reset your password.',
    forgot_your_password: 'Forgot your password?',
    inactive: '[inactive]',
    invalid_email_address: 'Invalid email address',
    invalid_phone_number: 'Invalid phone number',
    last_name: 'Last Name',
    last_name_required: 'Last name required',
    my_profile: 'My Profile',
    my_settings: 'My Settings',
    new_password: 'New Password',
    new_password_not_match: 'New password not matched with confirm password',
    new_password_required: 'New password required',
    old_password: 'Old Password',
    organization_name: 'Organization Name',
    password: 'Password',
    password_changed_successfully: 'Password changed successfully',
    password_criteria: 'Min 10 characters',
    password_criteria_min_count: '10 character minimum',
    password_dont_match: 'Passwords don’t match',
    password_doesnt_match: 'Password doesn’t match',
    password_length_should_be_10: 'Password length should be 10',
    password_length_should_be_10_character: 'Password must be 10 character min',
    password_required: 'Password required',
    password_reset: 'Password Reset',
    password_reset_email_sent: 'Instructions for resetting your password have been sent to {{email}}.',
    password_reset_message: 'If we found a matching account a password reset link will be landing in your email inbox shortly.',
    phone: 'Phone',
    phone_invalid: 'Phone number invalid',
    phone_number: 'Phone Number',
    phone_number_required: 'Phone Number required',
    phone_number_optional: 'Phone Number (Optional)',
    phone_optional: 'Phone (optional)',
    profile_picture: 'Profile Picture',
    profile_update: 'Profile Update',
    profile_updated_successfully: 'Profile updated successfully',
    reenter_password: 'Re-enter Password',
    remove_picture: 'Remove Picture',
    required_field: 'Required field',
    reset_password: 'Reset Password',
    reset_password_instruction: 'Enter your new password below.',
    return_to_sign_in: 'Return to Sign In',
    sign_in: 'Sign In',
    sign_out: 'Sign Out',
    sign_up: 'Sign Up',
    success: 'Success!',
    update_organization: 'Update Organization',
    update_password: 'Update Password',
    update_profile: 'Update Profile',
    upload_and_replace: 'Upload & Replace',
    your_password_hasbeen_reset: 'Your password has been reset.',
    department: 'Department',
    graphic_design: 'Graphic Design',
    sale_and_marketing: 'Sales & Marketing',
    customer_servie:'Customer Service',
    print_tech_or_press_operator: 'Print Tech / Press Operator',
    production: 'Production',
    management: 'Management',
    selection_required: 'Selection Required',
    select_department: 'Select Department'
  },
  folder: {
    create_a_folder: 'Create a Folder',
    create_folder: 'Create Folder',
    create_new_folder: 'Create New Folder',
    delete_folder: 'Delete Folder',
    delete_folder_approval_warning_msg: 'This folder contains one or more files that are associated to an Art Approval. By deleting this folder, all sub-folders and files will also be deleted and the files will be removed from the associated art approvals. You can’t undo this action.',
    download_folder: 'Download Folder',
    folder_name: 'Folder Name',
    folder_name_required: 'Folder name required',
    move_folder: 'Move Folder',
    move_selection: 'Move Selection',
    move_to: 'Move to',
    rename: 'Rename',
    rename_folder: 'Rename Folder',
    select_folder: 'Select Folder',
  },
  filter: {
    created_date_newest: 'Created Date (Newest)',
    created_date_oldest: 'Created Date (Oldest)',
    name_a_to_z: 'A to Z',
    name_z_to_a: 'Z to A',
    oldest_first: 'Oldest First',
    recent_first: 'Recent First',
    recently_added: 'Recently Added',
    recently_modified: 'Recently Modified'
  },
  art: {
    add_art: 'Add Art',
    add_art_name: 'Add Art Name',
    add_art_to_approval: 'Add to Art Approval',
    add_color: 'Add Color',
    add_decoration_method: '+ Add Decoration Method',
    add_description: 'Add a description…',
    add_file: 'Add File',
    add_files: 'Add Files',
    add_or_edit_decoration_method: 'Add/Edit Decoration Method',
    add_tag: 'Add Tag',
    add_to_art_portal: 'Add to Art Portal',
    added_to_art_portal: 'Added to Art Portal',
    alpha_channel: 'Alpha channel',
    all_art_types: 'All Art Types',
    art_details: 'Art Details',
    art_name: 'Art Name',
    art_name_required: 'Art name required',
    arts_only_allowed_to_add_to_approval: 'Only supported files can be added to Art Approvals',
    bulk_delete: 'Bulk Delete',
    bulk_delete_files: 'Bulk Delete Files',
    cannot_edit_tags_folders: 'You cannot edit tags for Folders',
    color_code_required: 'Color Code Required',
    color_name_required: 'Color Name Required',
    color_profile: 'Color profile',
    color_space: 'Color space',
    colors_with_count: 'Colors ({{colors}})',
    decoration_method: 'Decoration Method',
    decoration_method_name_required: 'Decoration method name required',
    decoration_method_required: 'Decoration Method Required',
    decoration_methods: 'Decoration Methods',
    delete_art_approval_file_warning_msg: 'Deleting this file will also remove it from the following Art Approvals. You can’t undo this action.',
    delete_confirm: '<b>{{x}}</b> will be permanently deleted. You can\'t undo this action.',
    delete_decoration_method: 'Delete Decoration Method',
    delete_decoration_method_warning: 'Are you sure you want to delete the following decoration method?',
    delete_decoration_method_warning_note: 'Note: Deleting this decoration method will remove it from all art instances with this label. This action cannot be undone.',
    delete_file: 'Delete File',
    delete_multiple_confirm: '<b>{{x}}</b> items will be permanently deleted. You can\'t undo this action.',
    deleted_files: 'Deleted Files',
    deleting_files: 'Deleting Files...',
    download_ai: 'Download .AI',
    download_art: 'Download Art',
    download_cdr: 'Download .CDR',
    download_complete: 'Download Complete',
    download_file: 'Download File',
    download_pdf: 'Download PDF',
    download_png: 'Download .PNG',
    download_source_file: 'Download Source File',
    download_x: 'Download .{{x}}',
    download_customize_stock_art_x: 'Download {{x}}',
    downloading: 'Downloading',
    edit_tags: 'Edit Tags',
    enter_color_name: 'Enter Color Name',
    file_details: 'File Details',
    height_in: 'Height (in)',
    message: 'Message',
    my_art: 'My Art',
    new: 'New',
    no_art_approvals_found: 'No art approvals found',
    not_in_art_portal: 'Not in Art Portal',
    rename_art: 'Rename Art',
    rename_file: 'Rename File',
    saving_copy_to_my_art: 'Saving copy to My Art...',
    search_art_approvals: 'Search Art Approvals',
    search_my_art: 'Search My Art',
    search_deleted_files: 'Search Deleted Files',
    select_art_approval: 'Select Art Approval',
    stock_art: 'Stock Art',
    trashed_csa_tooltip: 'This design has been moved to Deleted Files. Please recover the design from within the Deleted Files section in order to view or use the file.',
    upload_art_by_dragging_into_folder: 'Then you can upload art by dragging it to the folder.',
    upload_complete: 'Upload Complete',
    upload_failed: 'Upload Failed',
    upload_file: 'Upload File',
    upload_folder: 'Upload Folder',
    upload_file_first_time_message: 'Upload your art files or create a folder to get started',
    uploading: 'Uploading',
    preparing_upload: 'Preparing Upload....',
    preparing_your_upload: 'Preparing your upload...',
    width_in: 'Width (in)',
    x_decoration_method: '{{x}} Decoration Method',
    x_decoration_methods: '{{x}} Decoration Methods',
    x_results: '{{x}} Results',
    you_dont_have_folders: 'You don\'t have any folders!',
    usage: {
      add_storage: 'Add Storage',
      storage_almost_full: 'Your GraphicsFlow Drive is almost full! ',
      storage_full: 'Your GraphicsFlow Drive is full!',
      storage_usage_status: '<b class="text-uppercase">{{usage}}</b> of {{limit}} used',
      upgrade_for_more_space: 'Upgrade to get more space.',
    },
    customize: 'Customize',
    customize_stock_art: 'Customized Stock Art',
    original_stock_art: 'Original Stock Art',
    my_art_delete_file_confirmation_message: "Are you sure you want to delete this {{x}}? Once the {{x}} is deleted, it will be moved to ‘Deleted Files’. Deleted files can be recovered or permanently deleted.",
    my_art_bulk_delete_file_confirmation_message: "Are you sure you want to delete the selected files? Once the files are deleted, they will be moved to ‘Deleted Files’. Deleted files can be recovered or permanently deleted.",
    you_do_not_have_any_deleted_files: 'You do not have any deleted files.',
    deleting_all_files: 'Deleting selected files. This can take several minutes. Please wait until the operation is complete.',
    go_to_my_art: 'Go to My Art',
    deleted_files_notifier: {
      description_note: 'Please note that deleted files still count towards your storage.',
      description_additional_note: 'Deleted files are permanently deleted automatically after 30 days.',
      storage_usage_info_x: 'of {{totalStorage}} used'
    },
    files_deleted_successfully: 'Files Deleted Successfully',
    remove_from_art_portal: 'Remove from Art Portal',
    file_successfully_added_to_art_portal: 'File Successfully Added',
    file_successfully_removed_from_art_portal: 'File Successfully Removed',
    files_successfully_added_to_art_portal: 'Files Successfully Added',
    files_successfully_removed_from_art_portal: 'Files Successfully Removed',
    action_failed: 'Action Failed',
    files_are_unsupported: 'Files are unsupported',
    added_colors_with_count: 'Added Colors ({{colors}})'
  },
  navigation: {
    approvals: 'Art Approvals',
    clip_art: 'Clip Art',
    design_idea: 'Design Idea',
    design_ideas: 'Design Ideas',
    design_requests: 'Design Requests',
    fonts: 'Fonts',
    my_art: 'My Art',
    my_team: 'My Team',
    search_placeholder: 'Click or type \'/\' to search',
    stock_art: 'Stock Art',
    account_settings: 'Account Settings',
  },
  stock: {
    add_art: 'Add Art',
    add_stock_art: 'Add Stock Art',
    add_to_art_approval: 'Add to Art Approval',
    all_x: 'All {{x}}',
    all_clip_art: 'All Clip Art',
    all_design_ideas: 'All Design Ideas',
    all_fonts: 'All Fonts',
    back_to_all_categories: 'Back to All Categories',
    category: 'Category',
    clear_all_selected: 'Clear All Selected',
    clear_selection: 'Clear Selection',
    collection: 'Collection',
    color_count: 'Color Count',
    color_mode: 'Color Mode',
    details: 'Details',
    download_all_fonts: 'Download All Fonts',
    download_font: 'Download Font',
    download_fonts: 'Download Fonts',
    filter_x: 'Filter ({{x}})',
    no_of_colors: '# of Colors',
    no_results: 'No results',
    results_in_x: 'Results in {{x}}',
    search_results_for: 'Search results for:',
    search_results_with_count: '{{count}} Search results for: <span class="t-20-500-p">‘{{search}}’</span>',
    search_stock_art: 'Search Stock Art',
    see_all: 'See All',
    select_a_category: 'Select a Category',
    show_all_categories: 'Show All Categories',
    style: 'Style',
    type: 'Type',
    x_results: '{{x}} results',
    x_styles: '{{x}} styles',
    x_of_total_downloads: '{{x}} of {{total}} downloads',
    view_all_sub_categories: 'View All Sub-categories',
    usage: {
      add_downloads: 'Add Downloads',
      stock_art_download_warning: 'You’re about to reach your download limit!',
      stock_art_download_error: 'You’ve reached your download limit!',
      stock_art_download_upgrade: 'Upgrade to get more downloads.',
      stock_art_download_reset_info: 'Resets on {{date}}',
      stock_art_download_usage_status: '<b>{{x}}</b> of {{y}} downloads used',
    }
  },
  tag: {
    tags: 'Tags',
    art_tags: 'Art Tags',
    add_tag_instruction: 'Click <b>‘+ Add Tag’</b> to create a new Tag. You can also add a new tag from the <b>‘Art Tags’</b> section of your file.',
    add_tag_with_sign: '+ Add Tag',
    add_tag_placeholder: 'Add tag...',
    bulk_edit_tags_info: 'Tag(s) shown below may be assigned to one or more of your selected files. Not every tag is necessarily assigned to every file.',
    cannot_edit_tags_for_folders: 'You cannot edit tags for Folders',
    delete_tag: 'Delete Tag',
    delete_tag_warning: 'Are you sure you want to delete the following tag?',
    delete_tag_warning_note: 'Note: Deleting this tag will remove it from all art files it’s currently assigned to. This action cannot be undone.',
    edit_name: 'Edit Name',
    merge_tags: 'Merge Tags',
    merge_tag_warning: 'A tag called <span class="t-16-600-p">‘{{tagName}}’</span> already exists. Would you like to merge the tags together?',
    search_tags: 'Search Tags',
    remove_tag: 'Remove Tag',
    selected_files_have_no_tags: 'Your selected files have no tags.',
    selected_files_have_no_tags_info: 'Click <span class="t-14-500-h">‘Add Tag’</span> below to add a Tag to your selected files.',
    tag_already_exists: 'Tag name already exists',
    tag_name_required: 'Tag name required',
    tag_manager: 'Tag Manager',
    tag_name: 'Tag Name',
    tag_with_count: '{{count}} Tag',
    manage_active_tags_below: 'Manage active tags below.',
    remove_tag_warning: 'Are you sure you want to remove the following tag?',
    remove_tag_warning_note: 'Note: Removing this tag will remove it from the selected art files. This action cannot be undone.',
    use_tags_to_easily_group_and_filter_art_files: 'Use tags to easily group and filter art files',
    you_currently_have_no_tags: 'You currently have no tags.',
  },
  team: {
    additional_team_members_warning: 'Your plan includes up to {{totalMembers}} team members. Each additional team member is {{memberPrice}}/month.',
    admin: 'Admin',
    admin_rights_description: 'Admins have full access to Stock Art, My Art, and Art Approvals. They can view and edit all organization-level settings, including the ability to modify billing settings, purchase upgrades, manage teammates, and more.',
    admin_rights_org_settings: 'They will gain access to the organization’s settings',
    admin_rights_team_settings: 'They will be able to invite new teammates',
    charged_on_next_bill: '*You will be charged on your next bill',
    credit_on_next_bill: '*Credit will be applied to your next bill',
    demote: 'Demote',
    demote_this_teammate: 'Demote this Teammate?',
    demote_to_member: 'Demote to Member',
    demote_to_support: 'Demote to Support',
    demote_to_x_from_y_confirmation: 'Are you sure you want to demote this Teammate from {{x}} to {{y}}?',
    demotion_rights_org_settings_x: 'They will lose access to {{x}} Account Settings',
    demotion_rights_remove_teammate_option: 'They will be not be able to remove teammates',
    demotion_rights_my_team_settings: 'They will not be able to manage teammates',
    demotion_rights_art_portal_settings: 'They will lose access to Art Portal settings (if applicable)',
    demotion_rights_art_portal_options: 'They will not be able to hide stock art in Art Portal (if applicable)',
    demotion_rights_my_art: 'They will not be able to delete Files / Folders in My Art',
    demotion_rights_design_requests: 'They will not be able to delete Design Requests',
    demotion_rights_art_approvals: 'They will not be able to delete Art Approvals',
    invite_as: 'Invite as...',
    invite_team_member: 'Invite Team Member',
    invite_teammate: 'Invite Teammate',
    invite_teammate_additonal: 'Invite Teammate at {{price}}/month*',
    member: 'Member',
    member_rights: 'Members have full access to Stock Art, My Art, and Art Approvals. They can also access Account Settings, but are not able to view or edit: Policies and Disclaimers or Plans and Billing. They can invite other Members or Support users, but they cannot remove teammates.',
    my_team: 'My Team',
    owner: 'Owner',
    promote: 'Promote',
    promote_this_teammate: 'Promote this Teammate?',
    promote_to_admin: 'Promote to Admin',
    promote_to_member: 'Promote to Member',
    promote_to_x_from_y_confirmation: 'Are you sure you want to Promote this Teammate from {{x}} to {{y}}?',
    promotion_rights_org_settings_x: 'They will gain access to {{x}} Account Settings',
    promotion_rights_remove_teammate_option: 'They will be able to remove teammates',
    promotion_rights_my_team_settings: 'They will be able to manage teammates',
    promotion_rights_my_team_settings_member: 'They will be able to do some teammate management',
    promotion_rights_art_portal_settings: 'They will gain access to Art Portal settings (if applicable)',
    promotion_rights_art_portal_options: 'They will be able to hide stock art in Art Portal (if applicable)',
    promotion_rights_my_art: 'They will be able to delete Files / Folders in My Art',
    promotion_rights_design_requests: 'They will be able to delete Design Requests',
    promotion_rights_art_approvals: `They will be able to delete Art Approvals`,
    remove_team_member: 'Remove Team Member',
    remove_teammate: 'Remove Teammate',
    remove_teammate_warning: 'Are you sure you want to remove the following teammate? They will immediately lose access to your GraphicsFlow organization.',
    remove_teammate_warning_note: 'Note: Removing a team member will not impact any existing Art Approvals, folders, or files.',
    remove_teammate_warning_paid: 'You will be refunded a prorated amount of {{amount}} based on your billing cycle.',
    support: 'Support',
    support_rights_description: 'Support users have access to Stock Art, My Art, and Art Approvals. However, they are not able to delete files or art approvals. Support users cannot manage other teammates, and they are not able to access any organization-level settings.',
    transfer_ownership: 'Transfer Ownership',
    transfer_ownership_msg: 'There can only be one owner per team, so your new role will be Admin. Are you sure you want to transfer ownership to the following team member?',
  },
  organization: {
    account_settings_update: 'Account Settings Update',
    choose_file: 'choose a file',
    color_disclaimer: 'Color Disclaimer',
    color_disclaimer_required: 'Color Disclaimer required',
    contact_us: {
      contact_us: 'Contact Us',
      message: 'Can’t find what you’re looking for or need help with a design? Send us a message and we will follow up with you.',
      success_message: 'Thank you for contacting us! We have received your message and will follow up with you shortly.'
    },
    download_limit_exceed: 'Download Limit Exceeded',
    download_limit_exceed_message: 'You have reached your monthly download limit for stock art. Your download limit will reset on {{date}}. Upgrade to get more downloads.',
    downloads_used: 'downloads used',
    manage_plan: 'Manage Plan',
    organization_name_required: 'Organization name required',
    organization_profile: 'Organization Profile',
    privacy_policy: 'Privacy Policy',
    privacy_policy_required: 'Privacy policy required',
    policy_updated_successfully: 'Policies updated successfully',
    replace_with_default: 'Replace with default',
    reset: 'Reset',
    reset_to_default: 'Reset to Default',
    reset_to_default_color_disclaimer_msg: 'Are you sure you want to replace your existing Color Disclaimer with Graphic Flows default?',
    reset_to_default_privacy_policy_msg: 'Are you sure you want to replace your existing Privacy Policy with Graphic Flows default?',
    reset_to_default_terms_and_conditions_msg: 'Are you sure you want to replace your existing Terms & Conditions with Graphic Flows default?',
    storage_limit_exceeded: 'Storage Limit Exceeded',
    storage_limit_exceeded_message: 'Add more storage space or clear up some room by deleting files in order to save more art to your GraphicsFlow Drive.',
    terms_and_conditions: 'Terms & Conditions',
    terms_and_conditions_required: 'Terms & Conditions required',
    terms_of_service: 'Terms of Service',
    update_color_disclaimer: 'Update Color Disclaimer',
    update_privacy_policy: 'Update Privacy Policy',
    update_terms_and_conditions: 'Update Terms & Conditions',
    upload_profile_instruction: 'Drop an image file (.png, .jpg, or .gif) or',
    watermark_settings: 'Watermark Settings',
    tab: {
      art_approvals: 'Art Approvals',
      art_portal: 'Art Portal',
      changes: 'Changes',
      change_summary: 'Change Summary',
      change_summary_note: '<b>Note:</b> This price does not include any taxes or adjustments/credits based on changes to your plan.',
      change_summary_desc_1: 'To see what your next bill will be, navigate back to the',
      change_summary_desc_2: '<b>Plans & Billing</b> ',
      change_summary_desc_3: 'Dashboard and click on <b>View Payment History</b> at the top. Your payment data is encrypted and secure. All amounts shown are in USD.',
      current_monthly_total: 'Current Monthly Total',
      current_plan: 'Current Plan',
      policies_and_disclaimers: 'Policies and Disclaimers',
      plan_name: 'Plan Name',
      plans_and_billing: 'Plans and Billing',
      plans_type_changed: 'Plan type changed',
      plan_summary: "Plan Summary",
      profile: 'Profile',
      can_approve: 'Can Approve',
      can_comment: 'Can Comment',
      can_view: 'Can View',
      create_an_approval: 'Create an Approval',
      confirm_plan_changes: 'Confirm Plan Changes',
      copy_link: 'Copy Link',
      customize_your_plan: 'Customize your plan',
      customize_your_plan_desc: 'Review your selected plan as well as the available add ons to ensure your team has all the tools they need to succeed. If you have any questions or need help please don’t hesitate to',
      delete_x_art_approval: 'Delete ‘{{label}}’ Art Approval?',
      drag_n_drop: 'Drag & Drop',
      delete_art_approval: 'Delete Art Approval',
      display_settings: {
        display_settings: 'Display Settings',
        display_settings_info: 'Change the look and feel of your Art Portal with the options below. Remember to click the <span class="t-14-500-p">‘Update Display Settings’</span> button to save your changes.',
        colors: 'Colors',
        buttons_and_accents: 'Buttons & Accents',
        header_text: 'Header Text',
        header_background: 'Header Background',
        logo_size: 'Logo Size',
        logo_info: 'Click below to access the Organization Profile and upload a logo for your business.',
        header_description: 'Header Description',
        update_display_settings: 'Update Display Settings',
        organization_profile: 'Organization Profile'
      },
      embed_code: {
        embed_code: 'Embed Code',
        embed_code_info: 'You can embed the Art Portal into a custom website of your choice. Simply copy the code below and add it to your custom webpage.',
        embed_code_note: 'The embed version of the Art Portal does not include the catalog header.',
        copy_embed_code: 'Copy Embed Code',
        copy_embed_code_tooltip: 'Embed Code copied!',
      },
      view_catalog: 'View Catalog',
      invite_pending: 'Invite Pending',
      invite_people: 'Invite people to collaborate',
      get_shareable_link: 'Get shareable link',
      graphics_builder_add_on_will_be_removed: 'GraphicsBuilder Add On will be removed',
      leave_feedback: 'Leave feedback, annotate the file, or just say you like something. Comments you and other collaborators post will show up here.',
      link_copied: 'Link copied!',
      name_is_required: 'Name is required',
      manage_your_plan: 'Manage your plan',
      message: 'Message',
      move_down: 'Move Down',
      move_up: 'Move Up',
      no_comments_yet: 'No comments yet',
      review_term_n_condition: 'Review our Terms and Conditions',
      save_copy_to_my_art: 'Save Copy to My Art',
      type_your_name_to_sign: 'Type Your Name to Sign',
      the_following_changes_to_your_plan: 'The following changes to your plan will go into affect on your next billing date:',
      untitled_art_approval: 'Untitled Art Approval',
      uploaded_files_deleted: 'Uploaded files not stored in My Art will be deleted',
      update_your_plan_msg: 'All upgrades go into effect immediately. All downgrades with the exception of Stock Art Downloads go into effect immediately.',
      update_your_plan_msg1: 'Downgrades for Stock Art Downloads go into effect next billing cycle.',
      scheduled_changes_pending: 'Scheduled Changes Pending',
      seats_used: 'seats used',
      used: 'used',
      you_dont_have_any: 'You don\'t currently have any open approvals.',
      your_art_file_anywhere: 'your art files anywhere, or click to browse',
      view_art_approval: 'View Art Approval',
      will_be_notified: 'will be notified',
      write_a_description: 'Write a description',
      next_bill_amount: 'Next bill amount',
      navigation: {
        add_link: 'Add Link',
        additional_links: 'Additional Links',
        navigation: 'Navigation',
        custom_links: 'Custom Links',
        custom_link_info: 'You can add custom links to external web pages. These links will display in the header of your Art Portal.',
        link_text: 'Link Text',
        link_text_is_required: 'Link Text is required',
        url: 'URL',
        url_is_required: 'URL is required',
        invalid_url: 'Invalid URL',
        update_navigation: 'Update Navigation',
        display_need_help: 'Display <span class="t-16-500-s">‘Need Help’</span> option',
        display_contact_us: 'Display <span class="t-16-500-s">‘Contact Us’</span> option'
      },
      notifications: {
        notifications: 'Notifications',
        notifications_info_1: 'Choose who should receive email notifications when a ',
        notifications_info_2: ' form or ',
        notifications_info_3: ' has been submitted.',
        design_request: 'Design Request',
        x_team_members: '{{count}} Team Members',
        contact_us_error_msg: 'At least 1 selection is required',
        select_team_members: 'Select Team Member(s)',
        update_notifications: 'Update Notifications',
        notification_info_updated: 'Notification Info Updated Successfully',
      },
      plan_details: 'Plan Details',
      plan_price: 'Plan price',
      next_payment_due: 'Next Payment Due',
      card_on_file: 'Card on file',
      subscription_updated_msg: 'Subscription Updated Successfully',
      storage: 'Storage',
      storage_downgrade: 'Storage Downgrade',
      storage_upgrade: 'Storage Upgrade',
      storage_upgrade_disabled_msg: 'You must decrease your storage usage to select for this tier.',
      team_members: 'Team Members',
      team_members_base_plan_info_1: 'The {{planName}} plan includes {{baseUserCount}} users. Each user after that is',
      team_members_base_plan_amount_2: '{{baseUserPrice}}/month',
      team_members_base_plan_info_3: 'You can add or remove users in the',
      team_members_base_plan_info_4: 'section of Graphics Flow.',
      stock_art_downloads: 'Stock Art Downloads',
      stock_art_downloads_downgrade: 'Stock Art Downloads Downgrade',
      change_downgrade_scheduled: 'Cancel scheduled change?',
      stock_art_downgrade: 'Stock Art Downgrade',
      stock_art_downloads_from_to: 'Monthly Stock Art Download Limit (from {{from}} to {{to}})',
      stock_art_downgrade_scheduled: 'Stock Art Downgrade Scheduled',
      stock_art_downgrade_scheduled_info: 'This update will go into affect on your next billing date.',
      stock_art_downloads_downgrade_note: 'Reducing your download limit will not go into affect until your next billing date',
      stock_art_downloads_upgrade: 'Stock Art Downloads Upgrade',
      team_usage: 'Team Usage',
      update_payment_method: 'Update Payment Method',
      view_payment_history: 'View Payment History',
      see_plan_details: 'See Plan Details',
      reset_date: 'Resets {{date}}',
      x_monthly_total: '{{type}} Monthly Total',
      tooltips: {
        team_members: 'Total number of user accounts within your organization.',
        storage: 'Total storage used within your \'My Art\' section.',
        stock_art_downloads: 'Total number of unique Stock Art files you have downloaded this month.',
        plan_price_info: '*Please note that this price does not include any taxes or adjustments/credits, and may not reflect your actual monthly bill. Please view your Payment History for more details.'
      },
      account_cancellation: 'Account Cancellation',
      cancel_account_info: 'A 30 day notice is required for all cancellations, per our <a id="termsOfServiceLink" href="{{urlLink}}" class="text-decoration-none link-color" target="_blank">terms of service</a>. To suspend or cancel your account, please click the link below and submit a request.',
      cancellation_request_form: 'Cancellation Request Form',
      design_request_form:{
        design_request_form: 'Design Request Form',
        design_request_form_info: 'Customize the Design Request Form users will access in the Art Portal. You can capture additional information for requests by choosing which custom fields and options are required and visible on the form.',
        contact_info: 'Contact Info',
        contact_info_required:'(required)',
        custom_fields: 'Custom Fields',
        add_custom_field: 'Add Custom Field',
        single_line_text_input: 'Single-line Text Input',
        multi_line_text_input: 'Multi-line Text Input',
        dropdown_select: 'Dropdown Select',
        date_picker: 'Date Picker',
        field_type: 'Field Type',
        field_label: 'Field Label',
        field_choices: 'Field Choices',
        field_choices_info: 'Enter each choice on its own line. Each line will be a choice a customer can select within the dropdown.',
        upload_art: 'Upload Art',
        upload_art_info: 'Allow users to upload art files when submitting the design request form. You can then download these art files later directly from the design request.',
        upload_description: 'Add art files related to your request.',
        duplicate_error_mesg: 'Field label already exist!',
        update_design_request_form: 'Update Design Request Form',
        input: {
          singleLine: 'Single-line Text Input',
          multiLine: 'Multi-line Text Input',
          dropDown: 'Dropdown Select',
          datePicker: 'Date Picker'
        },
        atleast_two_field_required: 'At least two option is required'
      },
      color_palettes_management: {
        add_new_color_palette: 'Add New Color Palette',
        color_name: 'Color Name',
        color_palette_name: 'Color Palette Name',
        color_palettes: 'Color Palettes',
        color_palette_successfully_saved: 'Color Palette Successfully Saved',
        color_profiles: 'Color Profiles',
        color_profiles_warning_desc: 'This color palette contains both RGB and CMYK color profiles. Mixing color profiles within assets may require additional prep on the downloaded file for production purposes.',
        color_profile_disclaimer: {
          label: 'Color Profile Disclaimer',
          disclaimer_desc_1_x: 'You are switching to an <span class="t-16-500-p">{{color}}</span> color profile.',
          disclaimer_cmyk_to_rgb: 'This color profile is primarily used for web and digital media. Some printing equipment is capable of printing in RGB, so you may need to consult with your printer documentation or supplier.',
          disclaimer_rgb_to_cmyk: 'When switching from RGB to CMYK, the color may not convert exactly to the appearance displayed on the screen. CMYK should be used if you have a requirement from a printer or heat transfer supplier.',
          disclaimer_confimation_x: 'Do you want to proceed with switching to a {{color}} color profile?'
        },
        delete_color_palette: 'Delete Color Palette',
        delete_color_palette_desc: 'Are you sure you want to delete the following color palette? This action cannot be undone.',
        delete_color_palette_notification: 'Color Palette Successfully Deleted',
        manage_color_palettes: 'Manage Color Palettes',
        manage_color_palettes_desc1: 'Create and manage custom color palettes for use in the Stock Art Customizer tool. You can choose which palettes are visible or hidden in the Customizer.',
        manage_color_palettes_desc2: 'Color Information',
        manage_color_palettes_desc3: 'also plays a big role when working with custom colors and palettes.',
        manage_color_palettes_link: 'Learn more about RGB and CMYK color profiles.',
        new_color_palette: 'New Color Palette',
        save_color_palette: 'Save Color Palette',
        switch_to_cmyk: 'Switch to CMYK',
        switch_to_rgb: 'Switch to RGB',
        system_color_palette: 'System Color Palette',
        edit_color_palette: 'Edit Color Palette',
        changes_successfully_saved: 'Changes Successfully Saved',
        hide_color_palette: 'Hide color palette',
        show_color_palette: 'Show color palette',
        view_more: 'View More',
        view_less: 'View Less'
      },
    },
    organization_logo: 'organization logo'
  },
  watermark: {
    all_watermarks_on: 'All Watermarks On',
    center: 'Center',
    choose_default_watermark: 'Choose the defaults for your watermarks',
    dark: 'Dark',
    default_sample: 'Default Sample',
    display_watermarks: 'Display Watermarks',
    display_watermarks_hint: 'Watermark settings do not apply to Stock Art files',
    display_watermark_on_artapproval_option_msg: 'Display watermarks on all Art Approvals by default',
    light: 'Light',
    none: 'None',
    opacity: 'Opacity',
    position: 'Position',
    repeat: 'Repeat',
    rotate: 'Rotate',
    size: 'Size',
    text: 'Text',
    text_color: 'Text Color',
    text_required: 'Text required',
    update_default_watermark: 'Update Default Watermark',
    watermark_updated_successfully: 'Watermark updated successfully',
    watermark_settings_disabled_for_artType: 'Watermark settings disabled for {{artType}} files'
  },
  feedback: {
    copied: "Copied!",
    copy_email: "Copy email address",
    give_feedback: "Give Feedback",
    help_us: "Help improve GraphicsFlow!"
  },
  fileupload: {
    file_format_not_supported: 'File format not supported',
    file_item_canceled: 'Item canceled',
    file_size_exceeded: 'Max file size (50MB) exceeded',
    n_items_failed: '{{x}} items failed',
    single_item_failed: '{{x}} item failed',
    stop_upload: 'Stop Upload',
    upload_completed: 'Upload Complete!',
    upload_in_process: 'Upload In Progress',
    upload_n_files_failed: '{{x}} items failed to upload',
    upload_single_file_failed: '1 item failed to upload',
    upload_stopped: 'upload stopped',
    uploaded_n_files: '{{x}} items uploaded',
    uploaded_single_file: '1 item uploaded',
    uploaded_zero_file: 'No file uploaded',
    uploading_n_files: 'Uploading {{x}} items',
    uploading_single_file: 'Uploading 1 item',
    uploading: 'Uploading...',
  },
  paymentSetup: {
    please_update_payment_information: 'To continue using GraphicsFlow, please update your payment information.',
    please_contact_us: 'To continue using your GraphicsFlow account, please contact support for assistance.',
    update_now: 'Update Now',
    need_help_with_your_account: 'Need help with your account? Contact Us',
    subscription_cancelled_contact_gf_administator: 'Your subscription has been cancelled. Please contact the account Owner or Admin to reactivate your account.'
  },
  plans: {
    add_ons_info: {
      storage_info: 'Increase the amount of cloud storage you have to store, organize, and manage all your graphics.',
      stock_arts_info: 'Increase the total number of unique stock art files that you can download for offline use each month.'
    },
    are_you_sure_cancel_scheduled_changes: 'Are you sure? The following scheduled changes will be cancelled and your next bill will be {{total}}.',
    add_ons: "Add Ons",
    current_plan: 'Current Plan',
    change_plan: "Change Plan",
    bundle: {
      price_per_month: "{{price}}/month",
      you_save_per_month: "You save {{price}}/mo",
      total_value: "{{price}} Value!",
      plan_includes: "Plan Includes:",
      team_member: "{{count}} Team Members",
      storage: "{{count}} of Storage",
      stock_art_downloads: "{{count}} Stock Art Downloads per month"
    },
    bundle_plans: {
      elite: "Elite",
      elite_price: "Elite ({{price}}/month)",
      standard: "Starter",
      standard_price: "Starter ({{price}}/month)",
      pro: "Pro",
      pro_price: "Pro ({{price}}/month)",
      plan_price_elite: "Plan Price (Elite)",
      plan_price_standard: "Plan Price (Starter)",
      plan_price_pro: "Plan Price (Pro)",
      stock_art_downloads: "{{count}} Stock Art Downloads",
      included: "Included"
    },
    add_ons_details: {
      enable_add_on: "Enable Add On",
      remove_add_on: "Remove Add On",
      disabled_remove_storage_add_on: "Add On cannot be removed. Reduce amount of storage in use.",
      x_storage: "+{{storageValue}} Storage",
      x_storage_with_quantity: "{{storageValue}} Storage Add On ({{quantity}})",
      storage_add_on_description: "Increase the amount of cloud storage you have to store, organize, and manage all your graphics.",
      x_stock_art_downloads: "+{{stockArtValue}} Stock Art Downloads",
      x_stock_art_downloads_with_quantity: "{{stockArtValue}} Stock Art Downloads Add On ({{quantity}})",
      stock_art_downloads_add_on_description: "Increase the total number of unique stock art files that you can download for offline use each month.",
      team_member_with_quantity: "Additional Team Members ({{quantity}})"
    },
    cancel_scheduled_changes: 'Cancel Scheduled Changes',
    comparison: {
      team_members: 'Team Members',
      team_members_description: 'As your organisation grows, you can add more users and control their permission level.',
      storage: 'Storage',
      storage_description: 'Safe & secure cloud storage for you to store, organize, and manage all your graphics from one easy-to-use workspace.',
      stock_art_downloads: 'Stock Art Downloads',
      stock_art_downloads_description: 'Total number of unique stock art files that you can download for offline use each month.',
      private_branding: 'Private Branding',
      private_branding_description: 'Remove the powered by GraphicsFlow logo from Art Approvals and customer facing email notifications.',
      pro_graphics_collection: 'Pro Graphics Collection',
      pro_graphics_collection_description: 'Access to even more premium stock art for downloading and for use within Art Approvals.',
      public_catalog_collection: 'Art Portal',
      public_catalog_collection_description: 'Online digital art catalog, sales tool, lead generator and design request solution.',
      customize_stock_art: 'Stock Art Customizer',
      customize_stock_art_description: 'Customize any Stock Art graphic directly in the app and save for future use or add to an art approval.'
    },
    contact_us: 'contact us',
    downgrade: {
      downgrade_title: 'Downgrade will go into effect immediately',
      downgrade_message: 'By downgrading to the GraphicsFlow Starter plan you will lose access to the following:',
      public_stock_art_will_be_disabled: 'Art Portal will be disabled',
      design_request_section_will_be_removed: 'Design Request Section will be removed',
      private_branding_will_be_removed: 'Private Branding will be removed'
    },
    for_assitance: 'for assistance',
    enabled: "Enabled",
    review_change_summary: 'Review Change Summary',
    of_storage: '{{storage}} of storage',
    starts_at_per_month: 'Starts at {{price}}/month',
    selected_plan: 'Selected Plan',
    plans_billing: 'Plans & Billing',
    plan_comparison: 'Plan Comparison',
    private_branding: {
      enable_add_on: "Enable Add On",
      no_add_ons_enabled: "No Add Ons Enabled",
      price_per_month: "{{price}}/month",
      private_branding_add_on: "Private Branding Add On",
      private_branding: "Private Branding",
      remove_add_on: "Remove Add On",
      remove_private_branding_add_on: "Remove Private Branding Add On",
      remove_the_powered_by_gf_logo_from_art_approvals: "Remove the powered by GraphicsFlow logo from Art Approvals and customer-facing email notifications.",
      take_your_gf_org_to_next_level_with_addons: "Take your GraphicsFlow Organization to the next level with Add Ons.",
    },
    select_plan: 'Select Plan',
    graphics_builder: {
      access_to_exclusive_graphics_builder: 'Access to exclusive premium stock art released through GraphicsBuilder subscription.',
      graphics_builder: 'GraphicsBuilder',
      graphics_builder_add_on: 'GraphicsBuilder Add On',
      included: 'Included',
      remove_graphics_builder_add_on: 'Remove GraphicsBuilder Add On',
      remove_graphics_builder_message: 'By removing the GraphicsBuilder Add On, you will lose access to all GraphicsBuilder Stock Art assets starting your next billing cycle ({{date}}).'
    },
    pro_graphics_collection: {
      pro_graphics_collection: "Pro Graphics Collection"
    },
    unable_to_downgrade_plans: "Unable to downgrade to this plan based on current usage.",
    upgrade_to_plan: "Upgrade to {{plan}}",
    view_detailed_plan_comparison: 'View detailed plan comparison'
  },
  signup: {
    by_signing_up_you_agree: 'By signing up you agree to our ',
    create_your_account: 'Create Your Account',
    industry_best_art_content_library: 'Industry’s Best Art Content Library',
    money_back_guarantee: '15 Day Money Back Guarantee!',
    new_art_every_month: 'New Art Every Month',
    need_help: 'Need help?',
    storage_limit: '25 GB Storage',
    title: 'GraphicsFlow is the industry’s first and only graphics productivity tool built for print, promo, and decorated apparel businesses.',
    terms_and_conditions: 'Terms and Conditions',
    unlimited_art_approvals: 'Unlimited Art Approvals',
    primary_graphic_design_software: 'Primary graphic design software',
    add_preview: 'add preview'
  },
  setup: {
    add_email_address: 'Add Email Address',
    email_aleady_exists: 'An account with that email address already exist',
    email_is_already_entered: 'Email is already entered',
    i_will_do_it_later: 'I\'ll do it later',
    invite_your_team_to_join: 'Invite your team to join your organization on GraphicsFlow.',
    send_invites: 'Send Invites',
    whos_in_your_team: 'Who’s on your team?',
    you_must_enter_atleast_one_email: 'You must enter at least one email address to send an invite',
  },
  welcome_modal: {
    ok_lets_go: 'Okay, let\'s go!',
    watch_this_video_to_learn: 'Watch this video overview to learn about GraphicsFlow',
    welcome_to_gf: 'Welcome to GraphicsFlow!',
  },
  artLibraries: {
    "proGraphics": "Pro Graphics"
  },
  public_stock_art: {
    asset_now_showing_in_art_portal: 'Asset now showing in Art Portal',
    asset_now_hidden_in_art_portal: 'Asset now hidden in Art Portal',
    add_keywords: 'Add Keywords...',
    art_portal: 'Art Portal',
    art_portal_link: 'Art Portal Link',
    categories: 'Categories',
    clip_art: 'Clip Art',
    clip_art_following_keywords_not_showing: 'Clip Art with the following keywords will not show in the Art Portal.',
    choose_which_category_to_display_first_in_the_art_portal_for_design_ideas: 'Choose which category to display first in the Art Portal. If no category is selected, Design Ideas will be sorted by Recently Added regardless of category.',
    choose_which_category_to_display_first_in_the_art_portal_for_clip_art: 'Choose which category to display first in the Art Portal. If no category is selected, Clip Art will be sorted by Recently Added regardless of category.',
    copy_art_portal_link: 'Copy Art Portal Link',
    copy_link: 'Copy Link',
    changes_saved_successfully: 'Changes saved successfully',
    default_category: 'Default Category (optional)',
    design_ideas: 'Design Ideas',
    design_ideas_following_keywords_not_showing: 'Design Ideas with the following keywords will not show in the Art Portal.',
    clip_art_following_keywords_now_showing: 'Clip Art with the following keywords will not show in the Art Portal.',
    disabled: 'Disabled',
    enabled: 'Enabled',
    fonts: 'Fonts',
    hide_by_keyword: 'Hide by Keyword (optional)',
    link_copied: 'Link copied!',
    manage_settings: 'Manage Settings',
    public_stock_art_catalog: 'Public Stock Art Catalog',
    search_designIdea: 'Search Design Ideas',
    search_clipArt: 'Search Clip Art',
    search_font: 'Search Fonts',
    search_for_keywords_to_add: 'Search for keywords to add to them to the list',
    select_categories: 'Select Categories',
    select_design_ideas_categories: 'Select which Design Ideas categories to show in the Art Portal.',
    select_clip_art_categories: 'Select which Clip Art categories to show in the Art Portal.',
    set_default_category: 'Set Default Category',
    shareable_link_msg: 'Anyone with the Shareable Link can view the Art Portal. This also applies to the Embed option.',
    shareable_link_disable_msg: 'The Art Portal will not be viewable through the Shareable Link or via the Embed option.',
    shareable_link: 'Shareable Link',
    stock_art_assets_description: 'Choose which type(s) of stock art assets to show in your Art Portal. At least one selection is required.',
    stock_art_assets: 'Stock Art Assets',
    no_assests: {
      heading:'No Available Assets',
      description: 'There are no assets currently enabled for this design library.'
    },
    update_view_settings: 'Update View Settings',
    you_must_select_one_asset_type: 'You must enable at least one type of Stock Art Asset, or enable the My Art Assets.',
    view_art_portal: 'View Art Portal',
    view_catalog: 'View Catalog',
    view_settings: 'View Settings',
    select_categories_error_msg: 'You must select at least one category or subcategory.',
    my_art_asset_setting: {
      my_art_asset_label: 'My Art Assets',
      add_to_art_portal: '‘Add to Art Portal’',
      my_art_asset_note_1: 'My Art assets are displayed as the default section in the Browse menu.',
      my_art_asset_note_2: 'Please read below to learn how to add My Art files to Art Portal.',
      art_portal_help_banner_title: 'How to Add My Art to Art Portal',
      art_portal_help_banner_desc_1: 'To add My Art assets to Art Portal, go to ',
      art_portal_help_banner_desc_2: ' and find the file(s) you wish to add. Choose the ',
      art_portal_help_banner_desc_3: ' option from the file’s action menu, or from the bulk actions menu.',
      my_art_section_name: 'My Art Section Name',
      my_art_asset_note: 'Note: At least 1 art file from My Art needs to be added to Art Portal in order for this section to be displayed.',
      disply_my_art_at_first_label: 'Display Stock Art before My Art',
      disply_my_art_at_first_description: 'Make Stock Art assets the default section in the Browse menu.',
      disply_my_art_in_art_portal_label: 'Disable My Art Assets in Art Portal',
      disply_my_art_in_art_portal_description: 'This option can be used to hide all My Art files from the Art Portal while you take the time to choose what assets to include.'
    }
  },
  public_catalog_view: {
    add_notes: "Add Notes",
    add_item: "Add Item",
    asset_hidden_in_art_portal: 'Asset hidden in Art Portal',
    add_ur_personalized_details_here: "Add your personalization details here....",
    hide_in_art_portal: 'Hide in Art Portal',
    hide_selections: "Hide selections",
    show_in_art_portal: 'Show in Art Portal',
    submit_design_request: "Submit Design Request",
    submit_request: "Submit Request",
    view_selections: "View selections",
    x_item_added: "{{x}} item added",
    x_items_added: "{{x}} items added",
    edit_notes: 'Edit Notes',
    remove_item: 'Remove Item',
    empty_result_message: 'Sorry we couldn’t find any matching results. Please try a different keyword or change your selected filters.',
    first_name: 'First Name',
    last_name: 'Last Name',
    email_address: 'Email Address',
    phone: 'Phone',
    phone_number: 'Phone Number',
    contact_info: 'Contact Info',
    submit_your_design_request: 'Submit Your Design Request',
    design_request_submit_msg: 'When you submit your design request you will receive an email confirmation that will include all the details shown below including your notes.',
    browse_art_catalog: 'Browse Art Catalog',
    tell_us_about_your_request_label: 'Tell us about your request',
    tell_us_about_your_request: 'Tell us about your request...',
    ur_info_safe_n_secure_not_be_shared: 'Your information is safe and secure and will not be shared with anyone',
    selected_items_x: 'Selected Items ({{x}})',
    selected_art_preview_image: 'selected art preview image',
    request_submitted_successfully: 'Request Submitted Successfully!',
    request_success_msg: 'Thank you! We have received your design request and will follow up with you shortly. You will receive an email confirmation with the details shown below.',
    request_id: 'Request ID',
    date_submitted: 'Date Submitted',
    name: 'Name',
    request_description: 'Request Description',
    update: 'Update',
    no_notes_were_added: 'No notes were added',
    must_select_one_item_at_least_to_submit_designrequest: 'You must have at least 1 item selected in order to submit a design',
    additional_info: 'Additional Info',
    upload_art: 'Upload Art',
    upload_art_info: '{{x}}. Max 50MB per file.',
    drag_drop_content: 'Drag and drop files here',
    select_files: 'Select Files',
    custom_field_required: '{{x}} is required',
    upload_art_attachment: 'Upload Art ({{x}} attachments)',
    no_value_add: 'No {{x}} were added',
    no_attachments_were_added: 'No attachments were added',
    default_upload_art_info: 'Add art files related to your request. Max 50MB per file.',
    looking_for_more_content: 'Looking for more content?',
    explore_browser_menu_1: 'Click on the',
    explore_browser_menu_2: 'menu at the top of the page to see more!'
  },
  smart_designer: {
    smart_designer: 'Smart Designer',
    plan_detail: {
      smart_designer_add_on: 'Smart Designer Subscription Add On',
      smart_designer_disabled: 'Smart Designer Subscription Add On Disabled',
      sd_for_corel_draw: 'Smart Designer for CorelDRAW',
      sd_description: 'Your Smart Designer Subscription adds more than 150 features to CorelDRAW that automate and simplify art production.',
      sd_info_x: 'Your subscription includes {{baseDeviceCount}} Smart Designer Installations.',
      sd_additional_devices_info: 'Additional License Add On has also been enabled. ',
      manage_add_on: 'Manage Add On',
      x_installs_used: 'of {{totalInstallCount}} Installations used',
      remove_device: 'Remove Device',
      available_install_x: 'Available Install ({{installsCount}})',
      available_installs_x: 'Available Installs ({{installsCount}})',
      send_email_info: 'Download Link and Installation Instructions have been sent to ',
      send_email_additional_info: 'You can also request the email again if you need to install on additional devices, or would like to send to someone else on your team.',
      send_email: 'Send Email',
      sd_installation_email: 'Smart Designer Installation Email',
      sd_installation_email_info: 'Please enter the email address you would like to send this to. You can send the email to yourself or someone else on your team.',
      add_on_removal_disabled_info: 'Devices must be unused in order to remove the add on.'
    },
    subscription_detail: {
      sd_branding_description: 'The world’s top-selling CorelDRAW add-on software! Smart Designer adds more than 150 features to CorelDRAW that will automate and simplify art production.',
      learn_more: 'Learn More',
      view_system_requirements: 'View System Requirements',
      system_requirements_tooltip: '\u2022 Windows 10 or 11 \n \u2022 CorelDRAW Graphics Suite 2020 or newer \n \u2022 Intel Core i3 or faster (or AMD equivalent) \n \u2022 8 GB of RAM (minimum) \n \u2022 1.5 GB Storage (minimum)',
      add_ons: 'Add Ons',
      sd_subscription_title: 'Smart Designer Subscription',
      sd_additional_devices_subscription_title: 'Smart Designer Additional License ({{addOnQty}})',
      sd_features: 'Features:',
      sd_features_0: 'Install on {{baseDeviceCount}} Computers',
      sd_features_1: 'Browse Templates',
      sd_features_2: 'Quickly Customize and Personalize',
      sd_features_3: 'Add Effects to your Designs',
      sd_features_4: 'Tutorials and Guides',
      sd_features_5: '150+ Additional Features!',
      sd_features_6_x: 'Comes with {{baseDeviceCount}} Device Installations',
      x_additional_devices: '{{additionalDeviceCount}} Additional Devices',
      additional_devices_info_x: 'Your Smart Designer subscription comes with {{baseDeviceCount}} installations. Purchase an Additional License to install on more computers. Each Additional License comes with {{additionalDeviceCount}} installations.',
      important_information: 'Important Information',
      important_information_info: 'Smart Designer requires a Windows PC with CorelDRAW. Only purchase if you have a Windows machine with CorelDRAW, and you meet the minimum system requirements listed below:',
      system_requirement_1: 'Windows 10 or 11',
      system_requirement_2: 'CorelDRAW Graphics Suite 2020 or newer',
      system_requirement_3: 'Intel Core i3 or faster (or AMD equivalent)',
      system_requirement_4: '8 GB of RAM (minimum)',
      system_requirement_5: '1.5 GB of Storage (minimum)',
      additional_license: 'Additional License'
    },
  },
  welcome_csa_modal: {
    welcome_to_csa: 'Welcome to the Customization Options!',
    watch_this_video_to_learn: 'Watch a short video to get familiar with these tools and start customizing stock art today! You can also access this video and other help guides later by clicking on this help icon:',
    lets_go: 'Let\'s Go'
  },
  custom_stock_art: {
    back_to_x: 'Back to {{sectionName}}',
    customize: {
      customize: 'Customize',
      add_my_art: 'Add My Art',
      my_images: 'My Images',
      upload_image:'Upload Image',
      clip_art: 'Clip Art',
      edit_clip_art: 'Edit Clip Art',
      replace_clip_art: 'Replace Clip Art',
      view_all_layers: 'View All Layers',
      layer_position: 'Layer Position',
      font: 'Font',
      color: 'Color',
      outline: 'Outline',
      colorize_layer: 'Colorize Layer',
      undo_colorize_effect: 'Undo Colorize Effect',
      colorize_layer_unavailable_message: 'Colorizing not available for this layer',
      edit_my_images: 'Edit Image',
      clip_art_currently_selected: 'Clip art currently selected'
    },
    continue_customizing_my_design: 'Continue Customizing My Design',
    continue_customizing: 'Continue Customizing',
    save_design_storage_limit_exceeded_message: 'Add more storage space or clear up some room by deleting files in order to continue to save customized designs.',
    design_customizations: 'Design Customizations',
    design_customizations_desc_x: 'This is a list of your design customizations related to this Stock Art {{stockArtType}}. Click on a customized design to view and edit the design.',
    design_name_required: 'Design Name required',
    design_name: 'Design Name',
    in_app_stock_art_editing: 'Stock Art Customizer',
    in_folder: 'In Folder',
    save_as_new_customized_design: 'Save as New Customized Design',
    save_changes_desc_my_art: "Would you like to update your customized design with the new changes, or save this as a new customized design?",
    save_design_to_my_art: 'Save Design to My Art',
    save_design: 'Save Design',
    update_customized_design: 'Update Customized Design',
    view_in_my_art: 'View in My Art',
    your_customized_design_updated: 'Your customized design has been updated.',
    your_design_saved_to_my_art: 'Your design has been saved to My Art',
    select_color: {
      select_color: 'Select Color',
      current_color: 'Current Color',
      color_in_use: 'Colors in Use',
      default_color_palette: 'Default Color Palette',
      custom_color_picker: 'Custom Color Picker',
      edit_text_outline:'Edit Text Outline',
      outline_size:'Outline Size',
      colors: 'Colors',
      edit_text: 'Edit Text',
      colorize_layer_desc: 'Select a color to colorize the bitmap layer.'
    },
    dialog: {
      discard_customizations: 'Discard Customizations',
      discard_customizations_description: 'Your customized design has not been saved yet. Are you sure you want to discard all customizations? This action cannot be undone.',
      reset_design: 'Reset Design',
      reset_design_description: 'Are you sure you want to reset to the default design and discard all customizations? This action cannot be undone.',
      file_not_supported: 'File Not Supported',
      file_not_supported_description: 'This file is not supported for use with the Customize feature. Supported file types include: <b>.SVG, .PNG, .JPG, .TIF, .TIFF, .JPEG, .GIF, .PDF, .EPS, .CDR, .AI, .PS, .PSD<b>',
      file_size_limit: 'File Size Limit',
      file_size_limit_description: 'We’re sorry, but your file is too large and cannot be added. Max file size is 50 MB. Please reduce the size of the file and try again.',
      select_different_file: 'Select Different File'
    },
    move_to_front:'Move to front',
    move_to_back: 'Move to back',
    move_forward: 'Move forward',
    move_backward:'Move backward',
    hide_layer: 'Hide Layer',
    show_layer: 'Show Layer',
    this_feature_is_coming_soon: 'This feature is coming soon!',
    retry_corel_session_dialog: {
      header: 'Error Loading Customizer',
      description: 'There was a problem loading the Design Customization tools. How would you like to proceed?',
    },
    light_dark_preview: 'Light/Dark Preview',
    disabled_input_tooltip: 'Click the pencil to edit this text'
  },
  permanently_delete: {
    all: "Permanently Delete All",
    confirmation_msg_all: "Are you sure you want to permanently delete all files and folders? This action cannot be undone.",
    confirmation_msg_file: "Are you sure you want to permanently delete this file? This action cannot be undone.",
    confirmation_msg_folder: "Are you sure you want to permanently delete this Folder? This action cannot be undone.",
    delete: "Permanently Delete",
    file: "Permanently Delete File",
    folder: "Permanently Delete Folder",
  },
  recover: {
    choose_location: 'Choose Location',
    file: 'Recover File',
    folder: 'Recover Folder',
    msg: 'This {{x}} has been deleted. Detail view is not available unless the {{x}} is recovered. Would you like to recover the {{x}}?',
    recover_file_msg: 'This file has been deleted. Detail view is not available unless the file is recovered. Would you like to recover the file?',
    recover: 'Recover',
    to_view_file: 'Recover to View Details',
    successfully_recovered: 'Successfully Recovered',
    view_in_my_art: 'View in My Art',
  },
  reactivation: {
    success_header : 'It’s official! Your account has been reactivated successfully.',
    success_description: 'With GraphicsFlow, you\'re going to improve organization, boost productivity and change your art department from a sales bottleneck to a sales accelerant!',
    go_to_your_account: 'Go to your Account',
    welcome_back_x: 'Welcome Back, {{x}}!',
    welcome_back_description: 'Our plans may have changed since the last time you\'ve logged into your GraphicsFlow account. To ensure you\'re utilizing all that GraphicsFlow has to offer please select from one of the new plans below. ',
    welcome_back_description_note: 'Once you\'ve activated your account you will have access to all your previously saved data in My Art, Art Approvals, and Team Member accounts.',
    most_popular: 'Most Popular',
    reactivation_success: 'reactivation success',
  },
  warnings: {
    team_member_count_exceeds_title: 'Team Member Count Exceeds Selected Plan',
    team_member_count_exceeds_description_x: 'The selected plan includes up to {{userCountByPlan}} Team Members. Your Organization has {{existingUserCount}} Team Members created. You will be charged an additional {{userPrice}}/month per Team Member.',
    team_member_count_exceeds_note: 'Note: You can manage your Team Members from the My Team section once your account has been activated.',
    storage_usage_exceeds_title: 'My Art Storage Usage Exceeds Selected Plan',
    storage_usage_exceeds_description_x: 'The selected plan includes up to {{storageSizeByPlan}} of Storage. Your Organization has {{totalUsedStorageSize}} of storage in use. In order to continue you must upgrade your plan or enable the +{{additionalPlanToOpt}} Storage Add On.',
  },
  timeline: {
    title: 'Timeline',
    title_description: 'Please see below for a list of timeline events regarding activity for this art approval.',
    approval: {
      assignee_changed: '<b class="t-12-500-p">{{oldAssigneeName}}</b> changed the assignee to <b class="t-12-500-p">{{newAssigneeName}}</b>',
      item_added: '<b class="t-12-500-p">{{userName}}</b> added <b class="t-12-500-p">{{itemName}}</b>',
      added_comment: '<b class="t-12-500-p">{{userName}}</b> added a comment on <b class="t-12-500-p">{{itemName}}</b>',
      deleted_comment: '<b class="t-12-500-p">{{userName}}</b> deleted a comment on <b class="t-12-500-p">{{itemName}}</b>',
      approval_created: '<b class="t-12-500-p">{{userName}}</b> created the art approval',
      approval_archived: '<b class="t-12-500-p">{{userName}}</b> archived the art approval',
      approval_reopened: '<b class="t-12-500-p">{{userName}}</b> re-opened the art approval',
      resent_approval: '<b class="t-12-500-p">{{userName}}</b> resent the art approval',
      item_deleted: '<b class="t-12-500-p">{{userName}}</b> deleted <b class="t-12-500-p">{{itemName}}</b>',
      approval_approved: '<b class="t-12-500-p">{{userName}}</b> approved the art approval',
      item_approved: '<b class="t-12-500-p">{{userName}}</b> approved <b class="t-12-500-p">{{itemName}}</b>',
      item_commented_and_approved: '<b class="t-12-500-p">{{userName}}</b> commented on and approved <b class="t-12-500-p">{{itemName}}</b>',
      item_rejected: '<b class="t-12-500-p">{{userName}}</b> rejected <b class="t-12-500-p">{{itemName}}</b>',
      item_commented_and_rejected: '<b class="t-12-500-p">{{userName}}</b> commented on and rejected <b class="t-12-500-p">{{itemName}}</b>',
      collaborator_viewed: 'New collaborator <b class="t-12-500-p">{{userName}}</b> viewed the art approval',
      item_version_deleted: '<b class="t-12-500-p">{{userName}}</b> deleted design version <b class="t-12-500-p">{{versionName}}</b>',
      item_group_deleted: '<b class="t-12-500-p">{{userName}}</b> deleted <b class="t-12-500-p">{{itemName}}</b> design group',
      item_version_added: '<b class="t-12-500-p">{{userName}}</b> added design version <b class="t-12-500-p">{{versionName}}</b>',
      collaborator_viewed_after_updates: 'Art approval has been viewed since updates were made',
      sent_email_update: '<b class="t-12-500-p">{{userName}}</b> sent an updates email notification',
    },
  }
};
