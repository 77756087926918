<div class="stock-art-list cell grid-y height-100">
  <div class="filters-and-chips">
    <div class="filters-container cell shrink" [ngClass]="filterClass"
      [class.align-center]="type === StockArtType.Font" [class.align-justify]="!selectable">
      <gf-stock-art-filters [alignmentClass]="selectable ? 'align-center-middle' : '' "></gf-stock-art-filters>
      @if (type === StockArtType.Font && (isLarge$ | async)) {
        <gf-download-all-fonts></gf-download-all-fonts>
      }
    </div>
    @if (stockArtListQuery.activeFilterHeaders$ | async; as activeFilterHeaders) {
      <div class="chips-container cell shrink m-b-1"
        >
        <gf-stock-art-chips></gf-stock-art-chips>
      </div>
    }
  </div>
  <gf-stock-art-list-results-header></gf-stock-art-list-results-header>
  <div #scrollingBlock class="stock-art-list-wrapper cell auto position-relative">
    @if (stockArtListQuery.loading$ | async) {
      <div class="catalog-loader position-relative">
        <loading-indicator id="catalogLoader"></loading-indicator>
      </div>
    }
    @if (infinite$ | async; as stockArtList) {
      @if (!stockArtList?.length) {
        <stock-art-search-no-result></stock-art-search-no-result>
      }
      @if (!!stockArtList?.length) {
        <virtual-scroller
          #scroll
          [items]="stockArtList"
          [parentScroll]="scrollingBlock"
          [enableUnequalChildrenSizes]="true"
          (vsEnd)="scrolledToBottom($event.endIndex, stockArtList.length)">
          <!-- Arts -->
          @if (stockArtList?.length) {
            <div class="stock-art-list-container">
              <!-- Design Ideas & Clip Art (not Fonts) -->
              @if (!((stockArtListQuery.activeStockArtType$ | async) === StockArtType.Font)) {
                <div #container class="grid-x grid-margin-x mobile-margin-none"
                  >
                  @for (stockArt of scroll.viewPortItems; track trackStockArt($index, stockArt)) {
                    <div
                      class="item-card cell xlarge-3 large-4 medium-6 small-12 m-b-2 draw meet"
                      [class.selected]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)">
                      <!-- hideStockArtItemInfoFooter is set to selectable, because If list view is open with selectable has "true"
                      then we don't need to show the footer of the item. -->
                      <gf-stock-art-item [art]="stockArt | stockArtArt | async"
                        [associatedOrgId]="(stockArt | stockArtArt | async).assignedOrganizationId"
                        [isGraphicsBuilder]="!!stockArt.stockArtRecord?.artLibrary"
                        [showCustomizeStockArt]="billingPlansQuery.isCustomizeStockArtEnabled$ | async"
                        [hideStockArtItemInfoFooter]="selectable"
                        (openStockArtDetails)="openStockArtDetail(stockArt.stockArtId, stockArtDetailSections.CUSTOMIZE)"
                        (click)="onClick(stockArt)">
                      </gf-stock-art-item>
                      @if (selectable && selectMultiple) {
                        <div class="selection-layer">
                          <!-- TODO: Find a better way to make the whole card clickable for selection -->
                          <label [for]="'selectStockArt' + stockArt.stockArtId + '-input'" class="display-block">
                            <mat-checkbox name="selectStockArt"
                              id="selectStockArt{{stockArt.stockArtId}}"
                              class="select-stock-art-checkbox"
                              [checked]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)"
                              (change)="select(stockArt, $event.checked)">
                            </mat-checkbox>
                          </label>
                        </div>
                      }
                    </div>
                  }
                </div>
              } @else {
                <div #container class="grid-x grid-margin-x mobile-margin-none">
                  @for (stockArt of scroll.viewPortItems; track trackStockArt($index, stockArt)) {
                    <div
                      class="font-item-card cell large-6 small-12 m-b-2 draw meet"
                      [class.selected]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)">
                      <gf-font-card [font]="stockArt"
                        [selectable]="selectable"
                        [art]="stockArt | stockArtArt | async"
                        (click)="selectSingleArt(stockArt)">
                      </gf-font-card>
                      @if (selectable && selectMultiple) {
                        <div class="selection-layer font-item">
                          <label for="selectStockArt{{stockArt.stockArtId}}-input" class="display-block">
                            <mat-checkbox name="selectStockArt"
                              id="selectStockArt{{stockArt.stockArtId}}"
                              class="select-stock-art-checkbox"
                              [checked]="(stockArtListQuery.selectedStockArtIds$ | async)?.includes(stockArt.stockArtId)"
                              (change)="select(stockArt, $event.checked)">
                            </mat-checkbox>
                          </label>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
              <!-- Fonts -->
              @if ((stockArtListQuery.loadingResults$ | async)) {
                <div class="flex-container align-center-middle m-b-3 m-t-2">
                  <loading-indicator [diameter]="28" [showInfiniteScrollLoader]="true" [panelClass]="'loader m-r-1'"></loading-indicator>
                </div>
              }
            </div>
          }
          @if (stockArtListQuery.loading$ | async) {
            <loading-indicator id="listLoader"></loading-indicator>
          }
        </virtual-scroller>
      }
    }
  </div>
</div>
