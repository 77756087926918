import { Component } from '@angular/core';
import { Translations } from '@graphics-flow/shared/assets';

@Component({
  templateUrl: './version-approval-confirmation-dialog.component.html',
  styleUrl: './version-approval-confirmation-dialog.component.scss'
})
export class VersionApprovalConfirmationDialogComponent {

  constructor(public readonly translations: Translations) {}
}
