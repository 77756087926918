import { Pipe, PipeTransform } from '@angular/core';
import { ID } from '@graphics-flow/types';
import { ArtQuery } from '@graphics-flow/util';

@Pipe({
  name: 'watermarkId'
})
export class WatermarkIdPipe implements PipeTransform {

  constructor(private artQuery: ArtQuery) {
  }

  transform(artId: ID): string {
    const art = this.artQuery.getArtById(artId)
    if (!art) {
      return '';
    }

    const artExt = art.artExtensions.find(ext => !!ext.waterMarkId);
    if (!artExt) {
      return '';
    }

    return artExt?.waterMarkId.toString();
  }

}
