import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { ApiService, DesignRequestHttpService } from '@graphics-flow/api';
import {
  ArtApproval,
  ArtApprovalItemDetailSection,
  ArtApprovalPackage,
  CollaboratorLinkPackage,
  DesignRequest,
  ID
} from '@graphics-flow/types';

import { CollaboratorService } from '../../../../lib/data/collaborator/collaborator.service';
import { OrganizationService } from '../../organization/organization.service';
import { ApprovalService } from '../approval.service';
import { ApprovalDetailQuery } from './approval-detail.query';
import { ApprovalDetailState, ApprovalDetailStore } from './approval-detail.state';

@Injectable({
  providedIn: 'root'
})
export class ApprovalDetailService {

  activeVersionControlShelfId: ID;

  constructor(
    private readonly approvalDetailStore: ApprovalDetailStore,
    private readonly approvalDetailQuery: ApprovalDetailQuery,
    private readonly collaboratorService: CollaboratorService,
    private readonly apiService: ApiService,
    private readonly approvalService: ApprovalService,
    private readonly organizationService: OrganizationService,
    private readonly designRequestHttpService: DesignRequestHttpService
  ) {
  }

  private _haveCollaboratorLinkPackage = false;

  get haveCollaboratorLinkPackage(): boolean {
    return this._haveCollaboratorLinkPackage;
  }

  setCollaboratorLink(collaboratorLinkId: ID, orgUri: string): Observable<CollaboratorLinkPackage> {
    this.apiService.setOrgUri(orgUri);
    this._haveCollaboratorLinkPackage = true;
    return this.getCollaboratorLink(collaboratorLinkId);
  }

  // Set all the info that we need from the CollaboratorLink to get the ArtApproval/Arts/Collaborator
  getCollaboratorLink(collaboratorLinkId: ID): Observable<CollaboratorLinkPackage> {
    return this.collaboratorService.getCollaboratorLinkPackage(collaboratorLinkId).pipe(
      tap((data: CollaboratorLinkPackage) => {
        this.organizationService.setOrganizations([data.organization], data.organization);
        this.approvalDetailStore.update((state) => ({
          ...state,
          approvalId: data.collaboratorLink.artApprovalId,
          collaboratorId: data.collaborator?.collaboratorId ?? null,
          collaboratorLink: data,
          collaboratorLinkId: collaboratorLinkId
        }));
      })
    );
  }

  setApprovalId(approvalId: ID) {
    this.approvalDetailStore.update((state) => ({ ...state, approvalId }));
  }

  setCollaboratorLinkId(collaboratorLinkId: ID) {
    this.approvalDetailStore.update((state) => ({ ...state, collaboratorLinkId }));
  }

  getApproval(): Observable<ArtApproval> {
    return this.approvalDetailQuery.collaboratorLink$.pipe(
      mergeMap((collaboratorLink: CollaboratorLinkPackage) => {
        if (collaboratorLink) {
          this.approvalService.getCollaborators(collaboratorLink.collaboratorLink.artApprovalId).subscribe();
          return this.collaboratorService.getArtApprovalFromSharedLink(collaboratorLink.collaboratorLink.artApprovalId, collaboratorLink.collaboratorLink.collaboratorLinkId);
        } else {
          return this.approvalDetailQuery.approvalId$.pipe(
            mergeMap((id: ID) => {
              if (!this.approvalService.createNewArtApproval) {
                this.approvalService.getCollaborators(id).subscribe();
              }
              this.approvalService.createNewArtApproval = false;
              return this.approvalService.getApprovalPackage(id).pipe(
                map((data: ArtApprovalPackage) => {
                  this.approvalDetailStore.update((state) => ({
                    ...state,
                    approvalId: id,
                    relatedDesignRequest: []
                  }));
                  this.getRelatedDesignRequest(data.artApproval.artApprovalId).subscribe();
                  return data.artApproval;
                })
              );
            })
          );
        }
      })
    );
  }

  updateApprovalItemId(approvalId: ID, approvalItemId: ID) {
    this.approvalDetailStore.update((state) => ({
      ...state,
      approvalId: approvalId,
      approvalItemId: approvalItemId
    }));
  }

  changeDrawerStatus() {
    this.approvalDetailStore.update((state: ApprovalDetailState) => ({ ...state, isDrawerOpen: !state.isDrawerOpen }));
  }

  closeDrawer() {
    this.approvalDetailStore.update((state: ApprovalDetailState) => ({ ...state, isDrawerOpen: false }));
  }

  setActiveSection(section: ArtApprovalItemDetailSection) {
    this.approvalDetailStore.update((state) => ({
      ...state,
      activeSection: section,
      isDrawerOpen: true
    }));
  }

  reset() {
    this.approvalDetailStore.reset();
  }

  getRelatedDesignRequest(artApprovalId: ID): Observable<DesignRequest[]> {
    return this.designRequestHttpService.getDesignRequests(artApprovalId).pipe(
      tap((relatedDesignRequest: DesignRequest[]) => {
        this.approvalDetailStore.update((state) => ({ ...state, relatedDesignRequest }));
      })
    );
  }

  updateApprovalItemVersionId(approvalItemVersionId: ID) {
    this.approvalDetailStore.update((state) => ({ ...state, approvalItemVersionId }));
  }

  setActiveVersionControlShelfId(versionControlShelfId: ID): void {
    this.activeVersionControlShelfId = versionControlShelfId;
  }
}
