<div class="flex-container flex-dir-column height-100">
  @if (activeVersionItem$ | async; as activeVersionItem) {
    <div class="comments-wrapper flex-container flex-child-auto">
      @if (activeVersionItem.comments.length) {
        <div class="flex-container flex-dir-column flex-child-auto width-100">
          @for (comment of activeVersionItem.comments | getComments; track comment) {
            <gf-approval-comment
              [comment]="comment"
              [canReply]="!isGuest"
              [replies]="activeVersionItem.comments | getCommentReplies:comment.commentId"
              (commented)="addComment($event, activeVersionItem, comment.commentId)"
              (deleteComment)="deleteComment($event, activeVersionItem)"
              [disabled]="isArchived$ | async">
            </gf-approval-comment>
          }
        </div>
      } @else {
        <div class="align-self-middle flex-container flex-dir-column align-center-middle p-x-3">
          <p class="t-20-500-p m-b-2">{{translations.approval.no_comments_yet | translate}}</p>
          <p class="t-14-400-p">{{translations.approval.leave_feedback | translate}}</p>
        </div>
      }
  </div>
  @if (!isGuest) {
    <div class="add-comment-wrapper flex-child-shrink p-a-3">
      <gf-comment-input (commented)="addComment($event, activeVersionItem)" [disabled]="isArchived$ | async"></gf-comment-input>
    </div>
  }
}
</div>
